<template>
	<div
		style="
			display: flex;
			flex-direction: column;
			background-color: var(--stan-store-custom-background-color);
			border-radius: 10px;
			box-shadow: var(--stan-box-shadow);
		"
	>
		<!-- Container header -->
		<div class="d-flex flex-column align-items-center" style="padding: 15px 15px 0px 15px; cursor: pointer" @click="toggleShowLessons">
			<div class="d-flex flex-row justify-content-between align-items-center w-100" style="margin-bottom: 15px">
				<div class="d-flex flex-row align-items-center" style="gap: 12px; min-width: 100%">
					<div class="incompleted-module-circle"></div>
					<div class="d-flex flex-column" style="gap: 4px; flex-shrink: 100">
						<span class="para-4" style="color: var(--stan-store-custom-highlight-color)">Module {{ moduleNumber }}</span>
						<span class="para-3 font-semibold">{{ module.data.title }}</span>
					</div>

					<div style="display: flex; gap: 8px; flex-grow: 1; place-content: end; align-items: center">
						<div class="module-tracker-container">
							<span v-if="isModuleBeingDripped" class="module-dripped-container">
								<span class="para-4">🔒 {{ module.data.drip_settings.days }}d</span>
							</span>
							<span v-else class="para-4">0/{{ module.lessons.length }}</span>
						</div>

						<span v-if="!isModuleBeingDripped">
							<InlineSvg
								v-if="showLessons"
								src="/images/preview-icons/chevron-up.svg"
								style="fill: var(--stan-store-custom-highlight-color);"
							/>
							<InlineSvg v-else src="/images/preview-icons/chevron-down.svg" style="fill: var(--stan-store-custom-highlight-color);" />
						</span>
					</div>
				</div>
			</div>
		</div>

		<!-- Lessons -->
		<Transition name="expand" @enter="enter" @leave="leave">
			<div v-show="showLessons && !isModuleBeingDripped" ref="lessonsContainer" class="lessons-container">
				<div class="divider-bar" />
				<div
					v-for="lesson in module.lessons"
					:key="lesson.lesson_id"
					@click="handleChooseLesson(moduleNumber - 1, lesson)"
					class="lesson-item"
					:class="{
						'lesson-selected': isActiveLesson(lesson.lesson_id),
						'lesson-unselected': !isActiveLesson(lesson.lesson_id),
					}"
				>
					<div
						class="lesson-icon"
						:class="{
							'lesson-icon-selected': isActiveLesson(lesson.lesson_id),
							'lesson-icon-unselected': !isActiveLesson(lesson.lesson_id),
						}"
					>
						<InlineSvg v-if="lesson.data.video" src="/images/preview-icons/video-camera.svg" class="stroke-white" />
						<InlineSvg v-else src="/images/preview-icons/text-lines.svg" class="fill-white" />
					</div>
					<div class="d-flex flex-column" style="gap: 4px">
						<span class="para-3">{{ lesson.data.title }}</span>
					</div>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
	export default {
		name: 'ModuleLessonsContainer',
		data() {
			return {
				showLessons: true,
			}
		},
		props: {
			module: {
				type: Object,
				required: true,
				default: undefined,
			},
			activeLesson: {
				type: Object,
				default: undefined,
			},
			moduleNumber: {
				type: Number,
				default: 0,
			},
		},
		methods: {
			toggleShowLessons() {
				if (!this.isModuleBeingDripped) {
					this.showLessons = !this.showLessons
				}
			},
			handleChooseLesson(moduleId, lesson) {
				this.$emit('chooseLesson', moduleId, lesson)
			},
			isActiveLesson(lessonId) {
				return !this.isHome && this.activeLesson.lesson_id === lessonId
			},
			enter(el) {
				const { scrollHeight } = el
				el.style.maxHeight = '0'
				requestAnimationFrame(() => {
					el.style.maxHeight = `${scrollHeight}px`
				})
			},
			leave(el) {
				el.style.maxHeight = `${el.scrollHeight}px`
				requestAnimationFrame(() => {
					el.style.maxHeight = '0'
				})
			},
		},
		computed: {
			isModuleBeingDripped() {
				return this.module.status === 3 && this.module.data.drip_settings.days !== 0
			},
		},
	}
</script>

<style lang="scss" scoped>
	.lessons-container {
		overflow: hidden;
		transition: max-height 0.5s ease-in-out, height 0.5s ease;

		.lesson-item {
			width: 100%;
			gap: 12px;
			align-items: center;
			padding: 12px 15px;
			cursor: pointer;
			display: flex;
			transition: all 0.2s ease;

			&.lesson-unselected {
				background-color: transparent !important;
			}

			&.lesson-selected {
				background-color: var(--stan-store-highlight-10-opacity) !important;
			}

			.lesson-icon {
				min-height: 24px;
				height: 24px;
				min-width: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 24px;
				border-radius: 100%;

				&.lesson-icon-selected {
					background-color: var(--stan-store-custom-highlight-color) !important;
				}

				&.lesson-icon-unselected {
					background-color: var(--stan-store-highlight-10-opacity) !important;
					color: var(--stan-store-custom-highlight-color) !important;
				}
			}

			&:hover {
				background-color: var(--stan-store-highlight-5-opacity) !important;
			}
		}

		&:not(.hidden) {
			height: 100%; /* Full height */
		}

		&.hidden {
			height: 0;
		}
	}

	.expand-enter-active,
	.expand-leave-active {
		transition: max-height 0.3s ease-in-out;
	}

	.expand-enter,
  .expand-leave-to /* .expand-leave-active in <2.1.8 */ {
		max-height: 0;
	}

	/* Module Container */
	.module-container {
		background-color: var(--stan-store-custom-background-color);
		box-shadow: var(--stan-box-shadow);
		overflow: visible !important;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		gap: 3px;
	}

	/* Module Tracker Container */
	.module-tracker-container {
		color: var(--stan-store-custom-highlight-color);
		background-color: var(--stan-store-highlight-10-opacity) !important;
		border-radius: 4px;
		padding: 5px 6px;
		display: flex;
		min-width: fit-content;
		height: fit-content;
	}

	/* Divider */
	.divider-bar {
		width: 100%;
		height: 1px;
		background-color: var(--stan-store-highlight-25-opacity) !important;
	}

	/* Module Status Circles */
	.completed-module-circle {
		min-height: 24px;
		height: 24px;
		min-width: 24px;
		width: 24px;
		border-radius: 100%;
		background-color: var(--stan-store-custom-highlight-color);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.incompleted-module-circle {
		min-height: 24px;
		height: 24px;
		min-width: 24px;
		width: 24px;
		border-radius: 100%;
		border: 1px solid var(--stan-store-custom-highlight-color);
	}

	.module-dripped-container {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-content: center;
		justify-content: center;
	}

	/* Fonts */
	.para-3 {
		font-family: 'Inter', sans;
		font-size: 12px;
		line-height: 115%;
	}

	.para-4 {
		font-family: 'Inter', sans;
		font-size: 10px;
		line-height: 100%;
	}

	.font-semibold {
		font-weight: 600;
	}

	.font-bold {
		font-weight: 700;
	}

	.stroke-white {
		stroke: white !important;
	}

	.fill-white {
		fill: white !important;
	}
</style>
