<template>
	<NodeViewWrapper as="span">
		<div style="position: relative;" ref="resizableImageNode" :style="appliedStyleForMenu">
			<div v-if="isModifyingImage && showResize && !isMobile" class="d-block" :style="appliedStyleForMenu">
				<div class="menu-bar pl-3 pr-2 py-2 d-flex flex-wrap align-items-center resize-panel justify-content-end">
					<div class="panel-element panel-element-align" v-if="showAlignOptions">
						<InlineSvg
							class="menu-icon"
							src="/images/icons/ic-align-left.svg"
							fill="white"
							@click="updateAlign(alignLeft, true)"
						></InlineSvg>
					</div>
					<div class="panel-element panel-element-align" v-if="showAlignOptions">
						<InlineSvg
							class="menu-icon"
							src="/images/icons/ic-align-center.svg"
							fill="white"
							@click="updateAlign(alignCenter, true)"
						></InlineSvg>
					</div>
					<div class="panel-element panel-element-align" v-if="showAlignOptions">
						<InlineSvg
							class="menu-icon"
							src="/images/icons/ic-align-right.svg"
							fill="white"
							@click="updateAlign(alignRight, true)"
						></InlineSvg>
					</div>
				</div>
			</div>

			<img
				v-bind="node.attrs"
				ref="resizableImg"
				:width="displayWidth"
				:draggable="isDraggable"
				:data-drag-handle="isDraggable"
				:key="node.attrs.src"
			/>
			<div
				v-show="isModifyingImage && showResize && !isMobile"
				:style="resizeHandlerStyle"
				class="right-resize-handler"
				ref="rightResizeHandler"
			></div>
		</div>
	</NodeViewWrapper>
</template>
<script>
	import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
	import commonHelper from '~/global_helper/helpers.js'
	import { getOptimizeImageUrl, preloadImage, checkImage } from '~/stan-vue-shared/components/OptimizationImageTools.js'

	export default {
		components: {
			NodeViewWrapper,
		},
		props: nodeViewProps,
		mounted() {
			this.isMounted = true
			const defaultStyle = this.node.attrs.style ?? `max-width: 100%; display: block; ${this.alignLeft};`
			defaultStyle.split('; ').forEach(s => {
				s = s.replace(';', '')
				if (this.size.indexOf(s) !== -1) {
					this.updateWidth(s, false)
				}
				if (s.indexOf('width: ') !== -1) {
					// For explict mention of the image style width
					this.updateWidth(s, false)
				}
				if ([this.alignLeft, this.alignCenter, this.alignRight].indexOf(s) !== -1) {
					this.updateAlign(s, false)
				}
			})
			this.node.attrs.class = ''

			this.$refs.resizableImg.addEventListener('load', () => {
				this.originalWidth = this.$refs.resizableImg?.naturalWidth ?? 0
				this.displayWidth = this.$refs.resizableImg?.clientWidth ?? 0
				this.handleImageOptimization(this.displayWidth)
			})

			this.$refs.rightResizeHandler.addEventListener('mousedown', e => {
				this.resizeStarted = true
				this.initialEvent = e
				this.resizeInitialWidth = this.displayWidth
			})

			this.$refs.resizableImageNode.addEventListener('mouseover', () => {
				this.isModifyingImage = true
			})
			this.$refs.resizableImageNode.addEventListener('mouseleave', () => {
				this.isModifyingImage = false
			})

			document.body.addEventListener('mousemove', e => {
				if (!this.resizeStarted) return
				const difference = this.initialEvent.x - e.x
				this.displayWidth = this.resizeInitialWidth - difference
				if (this.$parent.$el.clientWidth && this.displayWidth > this.$parent.$el.clientWidth) {
					this.displayWidth = this.$parent.$el.clientWidth
					this.updateWidth(`width: 100%`, true)
				} else {
					this.updateWidth(`width: ${this.displayWidth}px`, true)
				}
			})

			document.body.addEventListener('mouseup', () => {
				if (this.resizeStarted) {
					this.resizeStarted = false
					this.handleImageOptimization(this.displayWidth)
				}
			})
		},
		beforeDestroy() {
			this.isMounted = false
		},
		data() {
			return {
				isMounted: false,
				isModifyingImage: false,
				tick: 0,
				size: ['max-width: 100%'],
				widthStyle: '',
				alignStyle: '',
				originalWidth: undefined,
				displayWidth: undefined,
				isResizing: false,
				initialEvent: undefined,
				resizeInitialWidth: undefined,
				checkImageTimeout: null,
				alignLeft: 'margin: 10px auto 10px 0px',
				alignCenter: 'margin: 10px auto 10px auto',
				alignRight: 'margin: 10px 0px 10px auto',
			}
		},
		computed: {
			isDraggable() {
				return this.isModifyingImage
			},
			showResize() {
				return this.extension.options.showResize
			},
			optimizeImage() {
				return this.extension.options.optimizeImage
			},
			optimizeMeta() {
				try {
					return JSON.parse(decodeURIComponent(this.node.attrs.optimizeMeta) || '{}')
				} catch {
					return {}
				}
			},
			isMobile() {
				return commonHelper.isMobile()
			},
			showAlignOptions() {
				return this.widthStyle !== 'max-width: 100%'
			},
			appliedStyle() {
				return `${this.widthStyle}; ${this.alignStyle}; display: block`
			},
			appliedStyleForMenu() {
				return `${this.alignStyle}; display: block; width: ${this.displayWidth}px`
			},
			resizeHandlerStyle() {
				return `left: ${this.displayWidth - 10}px; ${this.alignStyle};`
			},
		},
		methods: {
			updateOptimizeAttributes(imageMeta) {
				preloadImage(imageMeta.optimizeImageSrc)
					.then(() => {
						this.updateAttributes({
							optimizeMeta: encodeURIComponent(
								JSON.stringify({
									width: imageMeta.width,
									originalSrc: imageMeta.originalSrc,
								})
							),
							src: imageMeta.optimizeImageSrc,
						})
					})
					.catch(() => {})
			},
			async checkImage(imageMeta, retryCount = 0, maxRetry = 4) {
				const isImageReady = await checkImage(imageMeta.optimizeImageSrc)

				if (!this.isMounted) {
					return
				}

				if (isImageReady) {
					this.updateOptimizeAttributes(imageMeta)
				} else if (retryCount <= maxRetry) {
					this.checkImageTimeout = setTimeout(() => {
						this.checkImage(imageMeta, retryCount + 1, maxRetry)
					}, 1000)
				}
			},
			preloadImage(imageMeta) {
				// add timestamp to avoid browser cache
				preloadImage(`${imageMeta.optimizeImageSrc}?timestamp=${Date.now()}`)
					.then(() => {
						this.checkImage(imageMeta)
					})
					.catch(() => {
						this.checkImage(imageMeta)
					})
			},
			getImageExtension(url) {
				if (typeof url !== 'string' || !url.trim()) {
					return 'jpg'
				}

				// Extract the extension
				url = url.split('?')[0].split('#')[0]
				const extension = url
					.split('.')
					.pop()
					.toLowerCase()

				if (['jpg', 'jpeg', 'png', 'webp', 'gif'].indexOf(extension) !== -1) {
					return extension
				}

				return 'jpg'
			},
			async handleImageOptimization(width) {
				if (!this.optimizeImage || !width) {
					return
				}

				// clear previously fired optimization
				if (this.checkImageTimeout) {
					clearTimeout(this.checkImageTimeout)
				}

				if (this.optimizeMeta.width === width) {
					return
				}

				const originalSrc = this.optimizeMeta.originalSrc || this.node.attrs.src

				const maxWidthAllowed = 1024
				const imageExtension = this.getImageExtension(originalSrc)

				// dont optmize gifs
				if (imageExtension === 'gif') {
					return
				}

				const optimizeImageOptions = {
					format: imageExtension,
					quality: 80,
					width: Math.min(width, maxWidthAllowed),
				}

				const optimizeImageSrc = await getOptimizeImageUrl(originalSrc, { ...optimizeImageOptions })
				if (optimizeImageSrc === originalSrc) {
					return
				}

				this.preloadImage({ originalSrc, optimizeImageSrc, width })
			},
			updateWidth(value, updateAttributes) {
				this.widthStyle = value
				this.node.attrs.style = this.appliedStyle
				if (updateAttributes) {
					this.updateAttributes({ style: this.appliedStyle })
				}
			},
			updateAlign(value, updateAttributes) {
				this.alignStyle = value
				this.node.attrs.style = this.appliedStyle
				if (updateAttributes) {
					this.updateAttributes({ style: this.appliedStyle })
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.resize-panel {
		opacity: 0%;
		height: 50px;
		transition: opacity 0.1s ease-in-out;
		opacity: 100%;
		position: absolute;
		gap: 5px;
		width: inherit;
	}

	.resize-panel::before {
		border-color: #eee transparent transparent transparent;
		border-width: 9px;
		margin-left: -9px;
		left: 50%;
		top: 100%;
	}

	.panel-element {
		cursor: pointer;
		font-size: 0.875rem;
		background: black;
		opacity: 90%;
		color: white;
		border-radius: 5px;
		margin-bottom: auto;
		transition: 0.2s;
	}

	.panel-element-size {
		padding-top: 3px;
		padding-bottom: 3px;
		padding-left: 10px;
		padding-right: 10px;
		letter-spacing: -0.02em;
	}

	.panel-element-align {
		padding-left: 2px;
		padding-right: 2px;
	}

	img {
		cursor: move;
	}

	.size-lg {
		width: 100% !important;
	}

	.size-sm {
		width: 50% !important;

		@media (max-width: 767px) {
			width: 100% !important;
		}
	}

	.align-center {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.align-left {
		display: block;
		margin-left: 0px;
		margin-right: auto;
	}

	.align-right {
		display: block;
		margin-left: auto;
		margin-right: 0px;
	}

	.right-resize-handler {
		position: absolute;
		top: calc(50% - 25px);
		width: 5px;
		height: 50px;
		background-color: var(--stan-primary-light-color);
		border: 1px solid var(--stan-primary-soft-color);
		border-radius: 5px;
		cursor: ew-resize;
	}

	.menu-icon,
	.panel-element {
		opacity: 0.7;
	}

	.menu-icon:hover,
	.panel-element:hover {
		opacity: 1;
	}
</style>
