var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative" },
    [
      _c(
        "div",
        {
          class: {
            "creator-pro-feature-not-allowed": !_vm.isAffiliateShareAllowed
          }
        },
        [
          _c("div", { staticClass: "affiliate-link-subheader mb-3 text-sm" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-2 analytics-button d-md-flex d-none" },
              [
                _c(
                  "AppButton",
                  {
                    attrs: { size: "sm", outline: "" },
                    on: { click: _vm.navigateToAffiliateAnalytics }
                  },
                  [_vm._v("\n\t\t\t\t\tAffiliate Analytics\n\t\t\t\t")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "affiliate-link-resell-rights-section mb-1" },
            [
              _c(
                "div",
                { staticClass: "header d-flex justify-content-between w-100" },
                [
                  _c(
                    "div",
                    { staticClass: "toggle" },
                    [
                      _c("AppToggle", {
                        staticClass: "ml-2 mb-0 mt-1 app-toggle",
                        model: {
                          value: _vm.value.resell_rights.enabled,
                          callback: function($$v) {
                            _vm.$set(_vm.value.resell_rights, "enabled", $$v)
                          },
                          expression: "value.resell_rights.enabled"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "toggle-label" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "toggle-label-subtext para-3 text-light"
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tAll customers who buy this product can resell at " +
                                _vm._s(_vm.value.resell_rights.percentage) +
                                "% commission.\n\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _vm.value.resell_rights.enabled
                ? _c("div", { staticClass: "toggle-expand" }, [
                    _c("div", { staticClass: "revenue-share" }, [
                      _c("div", { staticClass: "para-4 text-bold" }, [
                        _vm._v("Affiliate Commission %")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("AppInput", {
                            staticClass: "mb-1 affiliate_share_percent_input",
                            class: {
                              "form-error":
                                _vm.validation.resell_rights.percentage.$error
                            },
                            attrs: {
                              id: "affiliate_share_resell_percent",
                              type: "number",
                              placeholder: _vm.$t("ex. 100"),
                              suffix: "%",
                              min: _vm.percent_min,
                              max: _vm.percent_max
                            },
                            model: {
                              value: _vm.value.resell_rights.percentage,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.value.resell_rights,
                                  "percentage",
                                  $$v
                                )
                              },
                              expression: "value.resell_rights.percentage"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "ml-3" },
                            [
                              _c("InlineSvg", {
                                staticClass: "settings_color cursor-pointer",
                                attrs: { src: "/images/icons/ic-options.svg" },
                                on: { click: _vm.openAdnvaedOption }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm.validation.resell_rights.percentage.$error
                          ? _c(
                              "small",
                              { staticClass: "stan-text-danger error" },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\tPercentage must be between " +
                                    _vm._s(_vm.percent_min) +
                                    "% and " +
                                    _vm._s(_vm.percent_max) +
                                    "%\n\t\t\t\t\t\t"
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    false
                      ? _c("div", { staticClass: "affiliate-share-message" }, [
                          _c(
                            "div",
                            { staticClass: "icon" },
                            [
                              _c("InlineSvg", {
                                attrs: { src: "/images/icons/ic-info.svg" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "para-3" }, [
                            _c("span", { staticClass: "text-bold" }, [
                              _vm._v("You've turned on Affiliate Share.")
                            ]),
                            _vm._v(
                              " Upon purchase, your buyer will receive a unique affiliate\n\t\t\t\t\t\tlink to be able to resell your product at " +
                                _vm._s(_vm.value.resell_rights.percentage) +
                                "% profit share. "
                            ),
                            _c("a", { attrs: { href: "#" } }, [
                              _vm._v("Learn More")
                            ])
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ]
          )
        ]
      ),
      _vm._v(" "),
      !_vm.isAffiliateShareAllowed
        ? _c("AppUpgrade", {
            attrs: {
              title: "Want to distribute Resell Rights for your product?"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("AffiliateShareOptionsModal", {
        attrs: {
          pageId: _vm.pageId,
          "page-slug": _vm.pageSlug,
          validation: _vm.validation,
          id: "AffiliateShareOptionsModal"
        },
        on: {
          addAffiliateShare: _vm.addAffiliateShare,
          removeAffiliateShare: index => _vm.removeAffiliateShare(index)
        },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "para-2" }, [
      _vm._v(
        "\n\t\t\t\tAllow your customers to resell this product and earn commission for each sale they make. "
      ),
      _c("a", { attrs: { href: "#" } }, [_vm._v("Learn More")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toggle-label-header" }, [
      _c("div", { staticClass: "para-2 text-bold" }, [
        _vm._v("Enable Affiliate Share")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "new-highlighter para-4 text-bold text-white" },
        [_vm._v("NEW")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }