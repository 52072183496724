<template>
	<AppModal
		id="affiliateShareOptionModal"
		title="Affiliate Share Settings"
		:large="true"
		secondaryAction="cancel"
		@secondary-cta="cancel"
		@open="modalOpen"
	>
		<div class="modal-main-container">
			<div class="toggle w-100 mt-3">
				<div>
					<AppToggle class="mb-0 app-toggle" v-model="value.resell_rights.affiliate_terms_and_condition.enabled" />
				</div>
				<div>
					<div class="para-2 text-bold">Add Affiliate Terms & Conditions</div>
					<div class="toggle-label-subtext para-3 text-light">
						Enable this to require your affiliates to first accept a Terms and Conditions before they can resell your product.
					</div>
					<div class="toggle-label">
						<div class="form-group mt-4 mb-2 w-100">
							<TipTapEditor
								:key="tick"
								v-model="value.resell_rights.affiliate_terms_and_condition.text"
								:disabled="!value.resell_rights.affiliate_terms_and_condition.enabled"
								class="tiptap-editor"
								:height="160"
								:showImage="false"
								:default-value="defaultAffiliateTermsAndCondition"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="mt-5 mb-4 para-2 text-bold">
				Unique Affiliate Links
			</div>
			<div class="toggle w-100">
				<div class="private-links">
					<AffiliateShareForm
						v-model="value.affiliate_shares"
						:pageId="pageId"
						:page-slug="pageSlug"
						:validation="validation.affiliate_shares"
						:showHelperText="false"
						@addAffiliateShare="addAffiliateShare"
						@removeAffiliateShare="index => removeAffiliateShare(index)"
					/>
				</div>
			</div>
		</div>
		<div class="d-flex flex-wrap justify-content-end mt-4">
			<AppButton name="close-affiliate-share-modal" class="py-2 md-flat ml-2 mt-1" style="width: 100px" @click="update">
				Close
			</AppButton>
		</div>
	</AppModal>
</template>

<script>
	import TipTapEditor from '~/components/shared/TipTapEditor'
	import AffiliateShareForm from '~/components/pages/AffiliateShareForm'
	import helper from '~/global_helper/helpers.js'

	export default {
		components: {
			TipTapEditor,
			AffiliateShareForm,
		},
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
			pageId: {
				type: Number,
				default: -1,
			},
			pageSlug: {
				type: String,
				default: undefined,
			},
		},
		created() {
			this.defaultAffiliateTermsAndCondition = helper.getAffiliateDefaultTermsAndCondition()
		},
		methods: {
			addAffiliateShare() {
				this.$emit('addAffiliateShare')
			},
			removeAffiliateShare(index) {
				this.$emit('removeAffiliateShare', index)
			},
			update() {
				$('#AffiliateShareOptionsModal').modal('hide')
			},
			cancel() {
				$('#AffiliateShareOptionsModal').modal('hide')
			},
			modalOpen() {
				if (!this.value.resell_rights.affiliate_terms_and_condition.text) {
					this.value.resell_rights.affiliate_terms_and_condition.text = this.defaultAffiliateTermsAndCondition
					this.tick++
				}
			},
		},
		computed: {
			hasUniqueAffiliateLinks() {
				return this.value.affiliate_shares.length > 0
			},
		},
		data() {
			return {
				defaultAffiliateTermsAndCondition: undefined,
				termsAndCondition: undefined,
				tick: 1,
			}
		},
	}
</script>

<style lang="scss" scoped>
	.modal-main-container {
		min-height: 400px;
	}
	.sub-heading {
		width: 100%;
		font-size: 14px;
		font-weight: 400;
		line-height: 19px;
		letter-spacing: 0em;
		text-align: center;
		color: var(--stan-blue-5);
	}
	.footnote {
		font-size: 10px;
		font-weight: 400;
		line-height: 14px;
		letter-spacing: 0em;
		text-align: left;
		color: var(--stan-blue-5);
	}
	.toggle {
		display: flex;
		align-items: flex-start;
		gap: 20px;
		align-self: stretch;
		.toggle-label-header {
			display: flex;
			align-items: center;
			gap: 8px;
		}
		.toggle-label {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 5px;
		}
	}
	.private-links {
		margin-left: 50px;
		width: 95%;
		@media screen and (max-width: 768px) {
			width: 78%;
		}
	}
</style>
