var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "table-wrapper" },
      [
        _vm.title || _vm.icon
          ? _c("div", { staticClass: "table-wrapper-header" }, [
              _c(
                "div",
                { staticClass: "stan-icon-wrapper" },
                [
                  _c("InlineSvg", {
                    staticClass: "stan-icon mr-2",
                    attrs: { src: _vm.icon }
                  }),
                  _vm._v(" "),
                  _c("h3", { staticClass: "text-bold" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._t("subheader"),
        _vm._v(" "),
        _c("div", { staticClass: "table-container" }, [
          _vm.isLoading || _vm.isEmpty
            ? _c(
                "div",
                { staticClass: "w-100 d-flex justify-content-center my-5" },
                [
                  _vm.isLoading
                    ? _c("InlineSvg", {
                        staticClass: "loading-icon",
                        staticStyle: {
                          position: "absolute",
                          "place-self": "center",
                          "justify-self": "center",
                          "align-self": "center"
                        },
                        attrs: { src: "/images/icons/ic-reload.svg" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-100 h-100 d-flex flex-column align-items-center align-self-center",
                      staticStyle: { gap: "20px" },
                      style: {
                        opacity: _vm.isLoading ? 0 : 1
                      }
                    },
                    [
                      _c("InlineSvg", {
                        attrs: { src: "/images/empty-table-icon.svg" }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column gap-15px text-center align-items-center"
                        },
                        [
                          _c("h2", { staticClass: "text-bold" }, [
                            _vm._v(_vm._s(_vm.emptyHeader))
                          ]),
                          _vm._v(" "),
                          _vm.emptySubheader
                            ? _c("span", {
                                staticClass: "para-1 text-light",
                                staticStyle: { "max-width": "300px" },
                                domProps: {
                                  innerHTML: _vm._s(_vm.emptySubheader)
                                }
                              })
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "table",
                {
                  staticClass: "para-2",
                  staticStyle: { "table-layout": "fixed", width: "100%" }
                },
                [_vm._t("default")],
                2
              ),
          _vm._v(" "),
          !_vm.isEmpty && !_vm.isLoading
            ? _c(
                "div",
                {
                  staticClass:
                    "table-wrapper-header justify-content-center justify-content-md-end"
                },
                [
                  _c(
                    "div",
                    { staticClass: "para-2 table-wrapper-header-actions" },
                    [
                      _c(
                        "div",
                        { staticClass: "header-custom-slot" },
                        [_vm._t("header-custom")],
                        2
                      ),
                      _vm._v(" "),
                      _vm.totalPages
                        ? _c(
                            "div",
                            { staticClass: "table-pagination" },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    `Page ${_vm.page} of ${_vm.totalPages}`
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                              _c("InlineSvg", {
                                class: { disabled: _vm.page === 1 },
                                attrs: {
                                  id: "previous-page",
                                  src: "images/icons/ic-chevron-up.svg"
                                },
                                on: { click: _vm.previousPage }
                              }),
                              _vm._v(" "),
                              _c("InlineSvg", {
                                class: {
                                  disabled: _vm.page === _vm.totalPages
                                },
                                attrs: {
                                  id: "next-page",
                                  src: "images/icons/ic-chevron-up.svg"
                                },
                                on: { click: _vm.nextPage }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              )
            : _vm._e()
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }