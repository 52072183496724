var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        id: "import-audience-success-modal",
        staticDataBackdrop: true,
        action: "Okay!"
      },
      on: { close: _vm.closeModal, cta: _vm.goToRecipients }
    },
    [
      _c(
        "div",
        { staticClass: "pb-1 d-flex w-100 justify-content-center" },
        [
          _c("InlineSvg", { attrs: { src: "/images/emojis/party-popper.svg" } })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "m-4 text-bold text-center" }, [
        _vm._v("Woohoo!")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-4 text-light para-2 text-center" }, [
        _c("p", [
          _vm._v(
            "\n\t\t\tYour import is running in the background and will finish shortly. If you want to send marketing emails to your imported contacts,\n\t\t\tthey'll need to "
          ),
          _c("span", { staticClass: "text-bold" }, [_vm._v("opt in!")])
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tYou can send an opt-in email to your contacts in\n\t\t\t"
          ),
          _c("span", { staticClass: "d-block text-bold" }, [
            _vm._v("Email Flows > Recipients.")
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }