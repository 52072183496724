<template>
	<AppModal
		ref="modal"
		:title="$t(modalTitle)"
		:subheading="$t(modalSubheading)"
		aria-labelledby="audienceBulkAddToProductModal"
		:overflow="true"
		:loading="loading"
		action="Grant Product Access"
		secondary-action="Cancel"
		@cta="submitModalForm"
		@secondary-cta="hide"
	>
		<Transition name="fade" mode="out-in">
			<div v-if="loading" key="loading">
				<div class="importing-animation">
					<InlineSvg src="/images/icons/ic-box.svg" />
					<InlineSvg src="/images/icons/ic-box.svg" />
					<InlineSvg src="/images/icons/ic-box.svg" />
				</div>
				<AppProgress :min="0" :max="totalCustomers" :value="processedCount" />
			</div>
			<div v-else key="content">
				<form id="audience_bulk_tagging_form">
					<div class="overflow-visible">
						<div class="overflow-visible border">
							<AppDropdown
								class="product-dropdown"
								:value="selectedProduct"
								:options="availableProducts"
								:searchable="true"
								placeholder="Select a product"
								image-width="23px"
								:right-align="false"
								display-label="name"
								label="name"
								:AppDropdown="true"
								:small="false"
								:rounded-icons="true"
								@select="selectProduct"
							>
							</AppDropdown>
						</div>
						<p class="para-3 text-danger mb-2" v-show="formErrors && formErrors.productSelectionErrors">
							{{ formErrors.productSelectionErrors }}
						</p>
					</div>
				</form>
			</div>
		</Transition>
	</AppModal>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex'

	export default {
		props: {
			tableTotalCustomers: {
				type: Number,
				default: 0,
			},
			filterArray: {
				type: Array,
				default: () => [],
			},
		},
		mounted() {
			this.fetchProductTagList()
		},
		data() {
			return {
				formErrors: {},
				loading: false,
				numberOfRowsToProcess: 100,
				processedCount: 0,
				userFanIds: [],
				selectedProduct: null,
				availableProducts: [],
			}
		},
		created() {
			this.resetForm()
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('Stores', ['getStore', 'getFunnels', 'getStoreLoading']),
			modalTitle() {
				let title = `Grant Product Access`

				if (this.loading) {
					title = 'Processing...'
				}

				return title
			},
			modalSubheading() {
				return `Product access will be given to ${this.tableTotalCustomers} ${this.customerText}`
			},
			totalCustomers() {
				return this.userFanIds.length
			},
			customerText() {
				return `customer${this.tableTotalCustomers > 1 ? 's' : ''}`
			},
		},
		methods: {
			...mapActions('Auth', ['fetchUser']),
			show() {
				this.resetForm()
				$(this.$refs.modal.$el).modal('show')
			},
			hide() {
				$(this.$refs.modal.$el).modal('hide')
				this.$emit('close')
				setTimeout(this.resetForm, 250)
			},
			selectProduct(product) {
				this.selectedProduct = product
			},
			fetchProductTagList() {
				// Only available for course & downloads
				const communityProducts = this.$commonHelper.buildProductList(
					this.getStore,
					this.getFunnels,
					this.$constants.PRODUCT_TYPES.COMMUNITY,
					false,
					true,
					this.user
				)
				const courseProducts = this.$commonHelper.buildProductList(
					this.getStore,
					this.getFunnels,
					this.$constants.PRODUCT_TYPES.COURSE,
					false,
					true,
					this.user
				)
				const downloadProducts = this.$commonHelper.buildProductList(
					this.getStore,
					this.getFunnels,
					this.$constants.PRODUCT_TYPES.DIGITAL_DOWNLOAD,
					false,
					true,
					this.user
				)
				this.availableProducts = []
				communityProducts.forEach(p => {
					this.availableProducts.push({ name: p.label, page_id: p.id, image: p.image })
				})
				courseProducts.forEach(p => {
					this.availableProducts.push({ name: p.label, page_id: p.id, image: p.image })
				})
				downloadProducts.forEach(p => {
					this.availableProducts.push({ name: p.label, page_id: p.id, image: p.image })
				})
			},
			validateForm() {
				let formStatus = true
				const errorObject = {}

				if (!this.selectedProduct) {
					errorObject.productSelectionErrors = 'Please select a product!'
					formStatus = false
				}

				Object.assign(this.formErrors, errorObject)
				return formStatus
			},

			async submitModalForm() {
				this.loading = true

				const status = this.validateForm()
				if (status) {
					try {
						const response = await this.$axios.get('v1/users/fans/bulk/user_fan_ids', {
							params: {
								page: '1',
								timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
								filter_array: JSON.stringify(this.filterArray),
							},
						})
						this.userFanIds = response.data.user_fan_ids
						this.numberOfRowsToProcess = Math.min(this.numberOfRowsToProcess, Math.ceil(this.userFanIds.length / 2))
						this.handleBatch()
						this.$emit('onProductGrant')
					} catch (error) {
						console.error(error)
					}
				} else {
					this.loading = false
				}
			},
			async handleBatch() {
				if (this.processedCount === this.totalCustomers) {
					setTimeout(() => {
						this.hide()
						this.$stanNotify({
							type: 'success', // can be 'info', 'warning', 'success', 'error'
							title: 'Success',
							text: `Product access has been granted.`,
							duration: 10000,
						})
					}, 250)
					return
				}
				const data = {
					page_id: this.selectedProduct.page_id,
					user_fan_ids: this.userFanIds.slice(this.processedCount, this.processedCount + this.numberOfRowsToProcess),
				}
				try {
					await this.$axios.put(`/v1/users/fans/product/bulk`, data)
					this.processedCount = Math.min(this.totalCustomers, this.processedCount + this.numberOfRowsToProcess)
					this.$stanAnalytics(`creator-customers-added-to-product`, {
						meta: { user_id: this.userId, username: this.username },
						props: data,
					})
					this.handleBatch()
				} catch (error) {
					console.error(error)
					Object.assign(this.formErrors, error.response.data)
					this.$stanNotify({
						type: 'error', // can be 'info', 'warning', 'success', 'error'
						title: this.$t('Product Access could not be granted, let us help you with that!'),
						text: error.response.data.message || this.$t('Please email friends@stanwith.me'),
						duration: 4000,
					})
					this.hide()
					this.$emit('close')
				}
			},

			resetForm() {
				this.formErrors = {}
				this.loading = false
				this.processedCount = 0
				this.userFanIds = []
				this.selectedProduct = null
			},
		},
		watch: {
			getStoreLoading: {
				immediate: true,
				handler(newVal) {
					if (!newVal) {
						this.fetchProductTagList()
					}
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.border {
		border-radius: 8px;
	}

	.product-dropdown::v-deep {
		.menu-wrapper .dropdown {
			&.is-open {
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}
			padding: 10px;
		}
	}

	.importing-animation {
		padding: 38px;
		display: flex;
		justify-content: center;
		align-items: center;
		svg::v-deep {
			path {
				fill: var(--stan-primary-soft-color);
			}
		}
		svg:first-child {
			width: 78px;
			height: 78px;
			margin: 5px;
			transform: translateX(30px) rotate(8.88deg);
			animation: float1 3s ease-in-out infinite;
		}
		svg:nth-child(2) {
			width: 118px;
			height: 118px;
			animation: float2 4s ease-in-out infinite;
		}
		svg:last-child {
			width: 65px;
			height: 65px;
			margin: 5px;
			transform: translateX(-30px) rotate(-10deg);
			animation: float3 3s ease-in-out infinite;
		}
	}
	@keyframes float1 {
		0% {
			transform: translateX(30px) rotate(8.88deg) translatey(-10px);
		}
		50% {
			transform: translateX(30px) rotate(8.88deg) translatey(0px);
		}
		100% {
			transform: translateX(30px) rotate(8.88deg) translatey(-10px);
		}
	}
	@keyframes float2 {
		0% {
			transform: translatey(0px);
		}
		50% {
			transform: translatey(-20px);
		}
		100% {
			transform: translatey(0px);
		}
	}
	@keyframes float3 {
		0% {
			transform: translateX(-30px) rotate(-10deg) translatey(0px);
		}
		50% {
			transform: translateX(-30px) rotate(-10deg) translatey(-10px);
		}
		100% {
			transform: translateX(-30px) rotate(-10deg) translatey(0px);
		}
	}
</style>
