<template>
	<div class="col-md-12 text-center app__user-image tooltip-profile-picture">
		<div class="image__area mb-3 mx-auto" id="pick-avatar" style="place-content: center">
			<div class="d-flex justify-content-center position-relative align-items-center ">
				<InlineSvg class="rotating loader-icon" src="/images/icons/ic-reload.svg" v-show="!user || imageLoading" />
				<div v-show="!imageLoading && user">
					<ImagePreview
						id="profile_photo"
						:source="profile_src"
						:width="100"
						:height="100"
						staticClass="user-card__image img-fluid"
						staticStyle="width: 100%; height: 100%; object-fit: cover"
						alt="profile photo"
						@load="handleImageLoad"
					/>
					<a class="abs-add-image cursor-pointer" v-if="showPlusSign" @click="showFileInput">
						<img src="/images/add-image.svg" class="img-fluid" />
					</a>
				</div>
			</div>

			<FileDropZone
				ref="profile-picture"
				:crop-aspect-ratio="1"
				:image="profile_src"
				@start-upload="uploadingFile"
				@uploaded="data => fileUploaded(data)"
				@cancel-crop="cropCancelled"
				accept="image"
				ghost
			/>
		</div>
	</div>
</template>
<script>
	import axios from 'axios'
	import FileDropZone from '~/components/uploads/FileDropZone'
	import ImagePreview from '~/stan-vue-shared/components/ImagePreview.js'

	export default {
		props: {
			user: { type: Object },
			showPlusSign: { type: Boolean, default: true },
			showSuccessNotification: { type: Boolean, default: true },
		},
		name: 'DragProfilePhoto',
		components: {
			FileDropZone,
			ImagePreview,
		},
		data() {
			return {
				url: null,
				profile_src: null,
				fileData: {
					type: Object,
					default: () => ({ url: null, name: 'Profile Picture' }),
				},
				previousFileName: null,
				previousFileUrl: null,
				uploadFileProgress: 0,
				uploadFileName: '',
				fileStatus: 0,

				imageLoading: true,
			}
		},
		mounted() {
			this.profile_src = this.user ? this.user.profile_image : ''
			const index = this.profile_src.lastIndexOf('/') + 1
			const filename = this.profile_src.substr(index)
			if (filename !== 'default-user.svg') {
				this.previousFileName = filename
				this.previousFileUrl = this.user ? this.user.profile_image : ''

				this.uploadFileName = filename
				this.fileStatus = 1
			}
		},
		computed: {
			fileUploadMaxBytes() {
				return 10 ** 9
			},
		},
		methods: {
			handleImageLoad() {
				this.imageLoading = false
			},
			uploadingFile() {
				this.previousFileUrl = this.profile_src
				this.uploadFileProgress = 0
				this.fileStatus = 0
			},
			cropCancelled() {
				this.profile_src = this.previousFileUrl
				this.uploadFileProgress = 0
				this.fileStatus = 1
			},
			fileUploaded(fileData) {
				if (this.uploadFileProgress === 100) {
					this.fileStatus = 1
				}

				this.imageLoading = true

				this.profile_src = fileData.src
				axios
					.post('v1/users/profile-page-setup', { profile_image: this.profile_src })
					.then(() => {
						this.$stanAnalytics('profile-image-updated', {
							meta: { user_id: this.user.user_id, username: this.user.username },
						})
						if (this.showSuccessNotification) {
							this.$stanNotify({ type: 'success', title: this.$t('Success!'), text: this.$t('Your profile image has been updated.') })
						}
						this.$emit('update_profile')
					})
					.catch(error => {
						const message = error.response && error.response.data ? error.response.data.message : ''
						this.$stanNotify({
							type: 'error',
							title: this.$t('Something went wrong'),
							text: message || this.$t('Please try again later.'),
						})
						this.$logError(error)
					})
				this.previousFileUrl = this.fileData.url
				this.$emit('updatedProfilePic')
			},
			onUploadProgress(payload) {
				this.uploadFileProgress = payload.progress
			},
			showFileInput() {
				this.$refs['profile-picture'].addFile()
			},
		},
	}
</script>

<style lang="scss" scoped>
	$fadeInDuration: 0.3s;
	.app__user-image {
		position: relative;
		min-height: 100px;
		.image__area {
			width: 100px;
			height: 100px;
			max-width: 100px;
			max-height: 100px;
			border-radius: 100%;
			//overflow: hidden;
			position: relative;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 100px;
			}
			.abs-add-image {
				position: absolute;
				right: 0;
				bottom: 0;
				width: 24px;
				height: 24px;
				display: inline-block;
			}
			.abos-loader {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 9;
			}
		}
		button {
			input {
				width: 100%;
				height: 100%;
				left: 0;
				right: 0;
				position: absolute;
				opacity: 0;
				z-index: 9;
			}
		}
	}

	.loader-icon {
		height: 50px;
		width: 50px;
		position: absolute;
	}

	.rotating {
		animation: rotate 2s ease-in-out infinite;
		animation-delay: calc($fadeInDuration / 2);
	}

	@keyframes rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
</style>
