var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "col-md-12 text-center app__user-image tooltip-profile-picture"
    },
    [
      _c(
        "div",
        {
          staticClass: "image__area mb-3 mx-auto",
          staticStyle: { "place-content": "center" },
          attrs: { id: "pick-avatar" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center position-relative align-items-center"
            },
            [
              _c("InlineSvg", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.user || _vm.imageLoading,
                    expression: "!user || imageLoading"
                  }
                ],
                staticClass: "rotating loader-icon",
                attrs: { src: "/images/icons/ic-reload.svg" }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.imageLoading && _vm.user,
                      expression: "!imageLoading && user"
                    }
                  ]
                },
                [
                  _c("ImagePreview", {
                    attrs: {
                      id: "profile_photo",
                      source: _vm.profile_src,
                      width: 100,
                      height: 100,
                      staticClass: "user-card__image img-fluid",
                      staticStyle:
                        "width: 100%; height: 100%; object-fit: cover",
                      alt: "profile photo"
                    },
                    on: { load: _vm.handleImageLoad }
                  }),
                  _vm._v(" "),
                  _vm.showPlusSign
                    ? _c(
                        "a",
                        {
                          staticClass: "abs-add-image cursor-pointer",
                          on: { click: _vm.showFileInput }
                        },
                        [
                          _c("img", {
                            staticClass: "img-fluid",
                            attrs: { src: "/images/add-image.svg" }
                          })
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("FileDropZone", {
            ref: "profile-picture",
            attrs: {
              "crop-aspect-ratio": 1,
              image: _vm.profile_src,
              accept: "image",
              ghost: ""
            },
            on: {
              "start-upload": _vm.uploadingFile,
              uploaded: data => _vm.fileUploaded(data),
              "cancel-crop": _vm.cropCancelled
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }