const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)
const DesignSystem = () => import('~/pages/design-system/DesignSystem').then(m => m.default || m)
const NPSSurvey = () => import('~/pages/nps-survey/NPSSurvey').then(m => m.default || m)

const moduleRoutes = [
	{
		path: '/dashboard',
		name: 'DashboardIndex',
		component: MainContainer,
		children: [
			{
				path: '',
				name: 'dashboard',
				component: () => import('~/pages/dashboard/Dashboard').then(m => m.default || m),
				meta: { pageTitle: 'Dashboard', breadcrumbs: [{ title: 'Home', active: true }] },
			},
		],
	},
	{
		path: '/ask-stanley',
		name: 'AskStanleyIndex',
		component: MainContainer,
		children: [
			{
				path: '',
				name: 'ask_stanley',
				component: () => import('~/pages/ask-stanley/AskStanley').then(m => m.default || m),
				meta: { pageTitle: 'Ask Stanley & Get Help', breadcrumbs: [{ title: 'Ask Stanley & Get Help', active: true }] },
			},
		],
	},
	{
		path: '/apps',
		component: MainContainer,
		children: [
			{
				path: '',
				name: 'apps',
				component: () => import('~/pages/apps/NavigationApps').then(m => m.default || m),
				meta: { pageTitle: 'Stan Apps', breadcrumbs: [{ title: 'Stan Apps', active: true }] },
			},
		],
	},
	{
		// path: '/nps_campaign/:token/:score',
		path: '/nps_campaign',
		name: 'nps_survey',
		component: NPSSurvey,
	},
	{
		path: '/design-system',
		name: 'design-system',
		component: DesignSystem,
	},
	{
		path: '/profile',
		name: 'ProfileIndex',
		component: MainContainer,
		children: [
			{
				path: '',
				name: 'profile',
				component: () => import('~/pages/stores/Profile').then(m => m.default || m),
				meta: {
					pageTitle: 'Header',
					breadcrumbs: [
						{ title: 'My Store', to: 'linksites' },
						{ title: 'Header', active: true },
					],
				},
			},
		],
	},
]

export default moduleRoutes
