var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass: "accordion-item card",
      class: { "no-header": !_vm.showHeader, "has-actions": !!_vm.actions }
    },
    [
      _vm.showHeader
        ? _c(
            "div",
            {
              staticClass: "card-header",
              class: { collapsed: !_vm.visible },
              attrs: {
                id: _vm.heading,
                "data-toggle": "collapse",
                "data-target": _vm.dataTarget,
                "aria-expanded": "true",
                "aria-controls": _vm.collapseId
              },
              on: {
                change: function($event) {
                  return _vm.$emit("changed", _vm.name)
                }
              }
            },
            [
              _c("div", { staticClass: "card-header-content-wrapper" }, [
                _vm.image
                  ? _c(
                      "div",
                      {
                        staticClass: "accordion-icon",
                        class: { error: _vm.error }
                      },
                      [_c("InlineSvg", { attrs: { src: _vm.image, alt: "" } })],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "accordion-title",
                    class: { "no-padding": !_vm.image }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "h3 text-truncate text-bold",
                        class: _vm.titleClass
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t\t"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.titleLabels.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "accordion-title-label-wrapper accordion-actions-wrapper"
                          },
                          [
                            _vm._l(_vm.titleLabels, function(action) {
                              return [
                                action.type === "tag" && action.visible
                                  ? _c(
                                      "span",
                                      {
                                        key: action.key,
                                        staticClass: "col-auto px-0",
                                        class: action.customClass,
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.$emit(
                                              "action-trigger",
                                              action
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("AppTag", {
                                          attrs: {
                                            label: action.label,
                                            backgroundColor:
                                              action.backgroundColor,
                                            textColor: action.textColor,
                                            image: action.image,
                                            svgFill: action.svgFill,
                                            fontWeight: 400
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm.columnData.length > 0
                  ? _c(
                      "div",
                      { staticClass: "accordion-column-data" },
                      [
                        _vm._l(_vm.columnData, function(column, index) {
                          return [
                            _c(
                              "div",
                              {
                                key: `accordion-column-${index}`,
                                staticClass: "column"
                              },
                              [_vm._v(_vm._s(column.value))]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "accordion-actions-wrapper" },
                  [
                    _vm._l(_vm.actions, function(action) {
                      return [
                        action.type === "tag" && action.visible
                          ? _c(
                              "span",
                              {
                                key: action.key,
                                staticClass: "col-auto px-0",
                                class: action.customClass,
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.$emit("action-trigger", action)
                                  }
                                }
                              },
                              [
                                _c("AppTag", {
                                  attrs: {
                                    label: action.label,
                                    backgroundColor: action.backgroundColor,
                                    textColor: action.textColor,
                                    image: action.image
                                  }
                                })
                              ],
                              1
                            )
                          : action.visible
                          ? _c(
                              "span",
                              {
                                key: action.key,
                                staticClass:
                                  "accordion-icon d-flex align-items-center",
                                class: action.class,
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.$emit("action-trigger", action)
                                  }
                                }
                              },
                              [
                                _c("InlineSvg", { attrs: { src: action.icon } })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }),
                    _vm._v(" "),
                    _vm.dropdown
                      ? _c(
                          "div",
                          { staticClass: "accordion-icon" },
                          [
                            _c("AppDropdownMenu", {
                              staticClass: "px-1",
                              attrs: { options: _vm.dropdown },
                              on: { selected: _vm.dropdownSelected }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.showAccordionArrow
                  ? _c("span", { staticClass: "accordion-icon indicator" }, [
                      _c("img", {
                        attrs: { src: "/images/icons/ic-chevron-down.svg" }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.columnData.length > 0
                  ? _c("div", { staticClass: "accordion-column-data-mobile" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.expanded,
                              expression: "!expanded"
                            }
                          ],
                          staticClass: "hidden-state"
                        },
                        [
                          _vm._l(_vm.columnData, function(column, index) {
                            return [
                              _c(
                                "div",
                                {
                                  key: `accordion-column-${index}`,
                                  staticClass: "column"
                                },
                                [
                                  _c("InlineSvg", {
                                    attrs: { src: column.icon, alt: "" }
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "para-2" }, [
                                    _vm._v(_vm._s(column.value))
                                  ])
                                ],
                                1
                              )
                            ]
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.expanded,
                              expression: "expanded"
                            }
                          ],
                          staticClass: "expanded-state"
                        },
                        [
                          _vm._l(_vm.columnData, function(column, index) {
                            return [
                              _c(
                                "div",
                                {
                                  key: `accordion-column-${index}`,
                                  staticClass: "column"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "para-1 text-dark" },
                                    [_vm._v(_vm._s(column.value))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "para-3 text-light" },
                                    [_vm._v(_vm._s(column.name))]
                                  )
                                ]
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "collapse",
          class: { show: _vm.visible },
          attrs: {
            id: _vm.collapseId,
            "aria-labelledby": _vm.heading,
            "data-parent": _vm.dataParent
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "card-body",
              class: [_vm.bodyClass, { "no-border px-0 pb-0": _vm.isWrapper }]
            },
            [_vm._t("default")],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }