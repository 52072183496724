<template>
	<AppSection :number="step" title="Pick a style" v-if="showButtonLayout">
		<div class="button-layout-wrapper mb-3 flex-wrap justify-content-center justify-content-md-start">
			<div class="col-auto p-0">
				<input type="radio" id="button" value="button" v-model="value.layout" />
				<label :class="['para-2']" for="button">
					<InlineSvg src="/images/icons/ic-button.svg" />{{ $t('Button') }}
					<span v-if="indicator === 'button'" class="indicator-circle"></span>
				</label>
			</div>
			<div class="col-auto p-0">
				<input type="radio" id="callout" value="callout" v-model="value.layout" />
				<label :class="['para-2']" for="callout">
					<InlineSvg src="/images/icons/ic-callout.svg" />{{ $t('Callout') }}
					<span v-if="indicator === 'callout'" class="indicator-circle"></span>
				</label>
			</div>
			<div class="col-auto p-0" v-if="showPreview">
				<input type="radio" id="preview" value="preview" v-model="value.layout" />
				<label :class="['para-2']" for="preview">
					<InlineSvg src="/images/icons/ic-video.svg" />{{ $t('Preview') }}
					<span v-if="indicator === 'preview'" class="indicator-circle"></span>
				</label>
			</div>
			<Transition name="fade-quick">
				<div class="col-auto p-0" v-if="showEmbed && embedExperimentEnabled">
					<input type="radio" id="embed" value="embed" v-model="value.layout" />
					<label :class="['para-2']" for="embed">
						<InlineSvg src="/images/icons/ic-video.svg" />{{ $t('Preview') }}
						<Transition name="fade-quick">
							<span v-if="indicator === 'embed'" class="indicator-circle"></span>
						</Transition>
					</label>
				</div>
			</Transition>
		</div>
	</AppSection>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		props: {
			showButtonLayout: {
				type: Boolean,
				default: true,
			},
			step: {
				type: String,
				required: true,
			},
			value: {
				type: Object,
				default: () => ({}),
			},
			indicator: {
				type: String,
			},
		},
		methods: {},
		computed: {
			...mapGetters('Auth', ['user']),
			showPreview() {
				return ['meeting', 'webinar', 'course', 'digital-download'].includes(this.$route.params.slug)
			},
			showEmbed() {
				return this.$route.params.slug === 'link'
			},
			embedExperimentEnabled() {
				return this.user.experiments?.product_media_preview_experiment
			},
		},
	}
</script>

<style lang="scss" scoped>
	.button-layout-wrapper {
		display: flex;
		align-items: center;
		gap: 20px;
		input[type='radio'] {
			display: none;
		}
		label {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 10px;
			margin-bottom: 0;
			position: relative;
			width: 90px;
			height: 90px;
			border: 1px solid var(--stan-gray-primary-color);
			border-radius: 5px;
			letter-spacing: -0.2px;
			&:hover {
				cursor: pointer;
			}

			.indicator-circle {
				position: absolute;
				top: 5px;
				right: 5px;
				width: 8px;
				height: 8px;
				background-color: var(--stan-primary-primary-color);
				border-radius: 50%;
			}
		}

		input:checked + label {
			background-color: var(--stan-gray-primary-color);
		}
	}
</style>
