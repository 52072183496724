var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrapper" },
    [
      _vm.screenSize > 768 || (_vm.screenSize <= 768 && !_vm.showCoursesList)
        ? _c("div", { staticClass: "main-section" }, [
            _c(
              "div",
              { staticClass: "exit-preview-banner", on: { click: _vm.close } },
              [_vm._v("\n\t\t\tClick to exit preview\n\t\t")]
            ),
            _vm._v(" "),
            _vm.showSection === "home"
              ? _c("div", [
                  _vm.bannerImage
                    ? _c(
                        "div",
                        { staticClass: "image-wrapper" },
                        [
                          _c("AppImage", {
                            staticClass: "banner-image",
                            attrs: { src: _vm.bannerImage }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        gap: "40px",
                        "margin-top": "24px"
                      }
                    },
                    [
                      _vm.course.data.title
                        ? _c("h1", { staticClass: "title-text" }, [
                            _vm._v(_vm._s(_vm.course.data.title))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.course.data.description
                        ? _c(
                            "div",
                            { staticClass: "description" },
                            [
                              _vm.course.data.description
                                ? _c("TipTapViewer", {
                                    staticClass: "px-5-mod",
                                    attrs: { "read-only": "" },
                                    model: {
                                      value: _vm.course.data.description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.course.data,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "course.data.description"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ])
              : _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _vm.lessonHasVideo
                      ? _c("Video", {
                          key: "video",
                          staticClass: "pages-block py-6",
                          attrs: {
                            "video-src": _vm.currentLesson.data.video,
                            "video-stream-src":
                              _vm.currentLesson.data.video_stream,
                            "fcp-complete": true,
                            showMask: false,
                            videoId: "preview-course-desktop-video"
                          }
                        })
                      : !_vm.hideVideo
                      ? _c("div", { staticClass: "video-placeholder mb-6" }, [
                          _c("div", { staticClass: "video-button-wrapper" }, [
                            _c("div", { staticClass: "video-icon" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    "https://assets.stanwith.me/graphics/video-play.svg"
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("span", [_vm._v("No Video Yet")])
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          gap: "40px"
                        }
                      },
                      [
                        _c("h1", { staticClass: "title-text" }, [
                          _vm._v(_vm._s(_vm.currentLesson.data.title))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "description" },
                          [
                            _vm.currentLesson.data.description
                              ? _c("TipTapViewer", {
                                  staticClass: "mb-5 px-5-mod",
                                  attrs: { "read-only": "" },
                                  model: {
                                    value: _vm.currentLesson.data.description,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.currentLesson.data,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "currentLesson.data.description"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentLesson.data.digital_assets.length
                              ? _c(
                                  "div",
                                  { staticClass: "lesson-support-wrapper" },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "lesson-support-title" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Supporting Materials"))
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "supporting-materials-list"
                                      },
                                      _vm._l(
                                        _vm.currentLesson.data.digital_assets,
                                        function(asset, index) {
                                          return _c("li", { key: index }, [
                                            _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.downloadFile(
                                                      asset
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "download-icon",
                                                  attrs: { src: _vm.iconUrl }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "download-text"
                                                  },
                                                  [_vm._v(_vm._s(asset.name))]
                                                )
                                              ]
                                            )
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("CourseSidebar", {
        attrs: {
          modules: _vm.modules,
          showCoursesList: _vm.showCoursesList,
          course: _vm.course,
          activeModule: _vm.currentModule,
          activeLesson: _vm.currentLesson,
          chooseLesson: _vm.chooseLesson
        },
        on: {
          chooseHome: _vm.chooseHome,
          toggleShowCoursesList: _vm.toggleShowCoursesList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }