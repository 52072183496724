<template>
	<AppModal
		ref="link-window"
		:title="!attributes.href ? $t('Insert Link') : $t('Edit Link')"
		class-name="block-modal fade"
		:close-button="false"
		:backdrop="false"
	>
		<div class="d-flex-column justify-content-left col-sm">
			<AppInput
				v-model.trim="link.text"
				icon="/images/icons/ic-edit.svg"
				class="form-group"
				:placeholder="$t('Name')"
				@input="$v.link.text.$touch"
				@blur="$v.link.text.$touch"
			/>
			<div v-if="$v.link.text.$error && !$v.link.text.minLength" class="stan-text-danger mb-3">
				{{ $t('Link name is required') }}
			</div>

			<AppInput v-model.trim="link.href" icon="/images/icons/ic-link.svg" class="form-group" placeholder="https://..." />
			<div v-if="$v.link.href.$error" class="stan-text-danger">
				{{ errorText }}
			</div>
		</div>
		<div class="d-flex flex-wrap justify-content-between mt-3 px-3">
			<AppButton name="tiptap-link-window-remove-link-button" outline class="py-2 md-flat mt-1" data-dismiss="modal" @click="removeLink">{{
				$t('Remove Hyperlink')
			}}</AppButton>
			<AppButton name="tiptap-link-window-save" class="py-2 md-flat ml-2 mt-1 w-100px" @click="handleSubmit">{{ $t('Save') }}</AppButton>
		</div>
	</AppModal>
</template>
<script>
	// eslint-disable-next-line import/extensions
	import { maxLength, required, url } from 'vuelidate/lib/validators'
	import helpers from '~/global_helper/helpers.js'

	export default {
		components: {},
		props: {
			editor: {
				type: Object,
				required: true,
			},
			attributes: {
				type: Object,
				default: null,
			},
		},
		data() {
			return {
				link: {
					href: '',
					text: '',
				},
			}
		},
		validations() {
			return {
				link: {
					href: {
						maxLength: maxLength(1024),
						required,
						url,
						forbiddenSites: value =>
							!['onlyfans', 'chaturbate.com', 'solchicks.io', 'pornhub.com'].some(item => value && value.indexOf(item) !== -1),
					},
					text: {
						maxLength: maxLength(1024),
						required,
					},
				},
			}
		},
		computed: {
			errorText() {
				if (!this.$v.link.href.required) {
					return this.$t('URL is required')
				}
				if (!this.$v.link.href.forbiddenSites) {
					return this.$t('Sorry, redirect to this site is not allowed on Stan')
				}
				return this.$t('Invalid URL')
			},
		},
		methods: {
			handleCancel() {
				$(this.$refs['link-window'].$el).modal('hide')
				this.$v.$reset()
			},
			handleSubmit() {
				this.appendLink()
				this.$v.link.$touch()
				if (!this.$v.link.$error) {
					if (this.attributes?.href == null) {
						this.addLink()
					} else {
						this.editLink()
					}
				}
			},
			addLink() {
				if (this.attributes.text) {
					this.editor
						.chain()
						.focus()
						.extendMarkRange('link')
						.setLink(this.prepareLink())
						.run()

					this.handleCancel()
				} else {
					this.editLink()
				}
			},
			editLink() {
				this.editor
					.chain()
					.focus()
					.deleteSelection()
					.run()

				const cursorPosition = this.editor.view.state.selection.anchor
				this.editor
					.chain()
					.focus()
					.insertContent(this.link.text)
					.setTextSelection({ from: cursorPosition, to: cursorPosition + this.link.text.length })
					.extendMarkRange('link')
					.setLink(this.prepareLink())
					.run()

				this.handleCancel()
			},
			removeLink() {
				this.editor
					.chain()
					.focus()
					.unsetLink()
					.run()
				this.handleCancel()
			},
			prepareLink() {
				return {
					href: this.link.href,
					text: this.link.text,
					target: '_blank',
				}
			},
			assignForm() {
				this.link.text = this.attributes.text
				this.link.href = this.attributes.href
			},
			onLoad() {
				if (this.attributes) {
					this.assignForm()
				}
				$(this.$refs['link-window'].$el).modal('show')
			},
			appendLink() {
				this.link.href = helpers.autoAddHttps(this.link.href)
				this.$v.link.href.$touch()
			},
		},
	}
</script>
