var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showButtonLayout
    ? _c("AppSection", { attrs: { number: _vm.step, title: "Pick a style" } }, [
        _c(
          "div",
          {
            staticClass:
              "button-layout-wrapper mb-3 flex-wrap justify-content-center justify-content-md-start"
          },
          [
            _c("div", { staticClass: "col-auto p-0" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.value.layout,
                    expression: "value.layout"
                  }
                ],
                attrs: { type: "radio", id: "button", value: "button" },
                domProps: { checked: _vm._q(_vm.value.layout, "button") },
                on: {
                  change: function($event) {
                    return _vm.$set(_vm.value, "layout", "button")
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                { class: ["para-2"], attrs: { for: "button" } },
                [
                  _c("InlineSvg", {
                    attrs: { src: "/images/icons/ic-button.svg" }
                  }),
                  _vm._v(_vm._s(_vm.$t("Button")) + "\n\t\t\t\t"),
                  _vm.indicator === "button"
                    ? _c("span", { staticClass: "indicator-circle" })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-auto p-0" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.value.layout,
                    expression: "value.layout"
                  }
                ],
                attrs: { type: "radio", id: "callout", value: "callout" },
                domProps: { checked: _vm._q(_vm.value.layout, "callout") },
                on: {
                  change: function($event) {
                    return _vm.$set(_vm.value, "layout", "callout")
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                { class: ["para-2"], attrs: { for: "callout" } },
                [
                  _c("InlineSvg", {
                    attrs: { src: "/images/icons/ic-callout.svg" }
                  }),
                  _vm._v(_vm._s(_vm.$t("Callout")) + "\n\t\t\t\t"),
                  _vm.indicator === "callout"
                    ? _c("span", { staticClass: "indicator-circle" })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm.showPreview
              ? _c("div", { staticClass: "col-auto p-0" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.layout,
                        expression: "value.layout"
                      }
                    ],
                    attrs: { type: "radio", id: "preview", value: "preview" },
                    domProps: { checked: _vm._q(_vm.value.layout, "preview") },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.value, "layout", "preview")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { class: ["para-2"], attrs: { for: "preview" } },
                    [
                      _c("InlineSvg", {
                        attrs: { src: "/images/icons/ic-video.svg" }
                      }),
                      _vm._v(_vm._s(_vm.$t("Preview")) + "\n\t\t\t\t"),
                      _vm.indicator === "preview"
                        ? _c("span", { staticClass: "indicator-circle" })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("Transition", { attrs: { name: "fade-quick" } }, [
              _vm.showEmbed && _vm.embedExperimentEnabled
                ? _c("div", { staticClass: "col-auto p-0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.layout,
                          expression: "value.layout"
                        }
                      ],
                      attrs: { type: "radio", id: "embed", value: "embed" },
                      domProps: { checked: _vm._q(_vm.value.layout, "embed") },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.value, "layout", "embed")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { class: ["para-2"], attrs: { for: "embed" } },
                      [
                        _c("InlineSvg", {
                          attrs: { src: "/images/icons/ic-video.svg" }
                        }),
                        _vm._v(_vm._s(_vm.$t("Preview")) + "\n\t\t\t\t\t"),
                        _c("Transition", { attrs: { name: "fade-quick" } }, [
                          _vm.indicator === "embed"
                            ? _c("span", { staticClass: "indicator-circle" })
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }