export const emptyImage =
	'data:image/gif;base64,R0lGODlhngHgAoABAAAAAP///yH5BAEKAAEALAAAAACeAeACQAL+jI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6zvf+DwwKh8Si8YhMKpfMpvMJjUqn1Kr1is1qt9yu9wsOi8fksvmMTqvX7Lb7DY/L5/S6/Y7P6/f8vv8PGCg4SFhoeIiYqLjI2Oj4CBkpOUlZaXmJmam5ydnp+QkaKjpKWmp6ipqqusra6voKGys7S1tre4ubq7vL2+v7CxwsPExcbHyMnKy8zNzs/AwdLT1NXW19jZ2tvc3d7f0NHi4+Tl5ufo6err7O3u7+Dh8vP09fb3+Pn6+/z9/v/w8woMCBBAsaPIgwocKFDBs6fAgxosSJFCtavIgxo8b+jRw7evwIMqTIkSRLmjyJMqXKlSxbunwJM6bMmTRr2ryJM6fOnTx7+vwJNKjQoUSLGj2KNKnSpUybOn0KNarUqVSrWr2KNavWrVy7ev0KNqzYsWTLmj2LNq3atWzbun0LN67cuXTr2r2LN6/evXz7+v0LOLDgwYQLGz6MOLHixYwbO34MObLkyZQrW76MObPmzZw7e/4MOrTo0aRLmz6NOrXq1axbu34NO7bs2bRr276NO7fu3bx7+/4NPLjw4cSLGz+OPLny5cybO38OPbr06dSrW7+OPbv27dy7e/8OPrz48eTLmz+PPr369ezbu38PP778+fTr27+PP7/+/fz++/v/D2CAAg5IYIEGHohgggouyGCDDj4IYYQSTkhhhRZeiGGGGm7IYYcefghiiCKOSGKJJp6IYooqrshiiy6+CGOMMs5IY4023ohjjjruyGOPPv4IZJBCDklkkUYeiWSSSi7JZJNOPglllFJOSWWVVl6JZZZabslll15+CWaYYo5JZplmnolmmmquyWabbr4JZ5xyzklnnXbeiWeeeu7JZ59+/glooIIOSmihhh6KaKKKLspoo44+Cmmkkk5KaaWWXopppppuymmnnn4Kaqiijkpqqaaeimqqqq7KaquuvgprrLLOSmuttt6Ka6667sprr77+Cmywwg5LbLHGHotebLLKLstss84+C2200k5LbbXWXottttpuy2233n4Lbrjijktuueaei2666q7LbrvuvgtvvPLOS2+99t6Lb7767stvv/7+C3DAAg9McMEGH4xwwgovzHDDDj8MsR0FAAA7'

export const emptyImage73 =
	'data:image/gif;base64,R0lGODlhSQBJAIABAAAAAP///yH5BAEKAAEALAAAAABJAEkAQAJPjI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6zvf+DwwKh8Si8YhMKpfMpvMJjUqn1Kr1is1qt9yu9wsOi8fksvmMTquhBQA7'

// Generate query parameters string
function serialize(obj) {
	const str = []
	for (const p in obj) str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
	return str.join('&')
}

// Optimize image for width and quality
export const optimizeImage = (src, options = {}) => {
	if (!src) return src
	const qs = serialize(options)
	return src + '?' + qs
}

export const PRODUCT_TYPES = {
	COMMUNITY: 'community',
	COURSE: 'course',
	DIGITAL_DOWNLOAD: 'digital-download',
	FULFILLMENT: 'fulfillment',
	LEAD_MAGNET: 'lead-magnet',
	LINK: 'link',
	MEETING: 'meeting',
	MEMBERSHIP: 'membership',
	WEBINAR: 'webinar',
	RESELL: 'resell',
	getAll: function() {
		return [
			this.COMMUNITY,
			this.COURSE,
			this.DIGITAL_DOWNLOAD,
			this.FULFILLMENT,
			this.LEAD_MAGNET,
			this.LINK,
			this.MEETING,
			this.MEMBERSHIP,
			this.WEBINAR,
			this.RESELL,
		]
	},
	getLeadGeneratingPages: function() {
		return [this.COMMUNITY, this.COURSE, this.DIGITAL_DOWNLOAD, this.FULFILLMENT, this.MEETING, this.MEMBERSHIP, this.WEBINAR, this.RESELL]
	},
}
export const SOCIAL_ICONS_ORDERING = [
	'instagram',
	'tiktok',
	'mail_to',
	'facebook',
	'youtube',
	'link',
	'pinterest',
	'linkedin',
	'twitter',
	'spotify',
	'apple_podcast',
	'amazon_music',
	'etsy',
	'discord',
	'snapchat',
	'twitch',
	'vimeo',
]
/**
 *
 * @param {number} amount
 * @param {string} [currency='USD']
 * @param {string} [locale='en-US']
 * @param {boolean} [longSymbolUs=false] displays US$ instead of $
 * @returns {string}
 */
export function formatCurrency(amount, currency = 'USD', locale = 'en-US', longSymbolUs = false) {
	const minimumFractionDigits = (amount * 10) % 10 > 0 ? 2 : 0
	return (
		(longSymbolUs && currency === 'USD' ? 'US' : '') +
		Intl.NumberFormat(locale, { style: 'currency', currency, minimumFractionDigits, maximumFractionDigits: 2 }).format(amount)
	)
}

export function hexToRGB(hex) {
	const color = {
		r: 0,
		g: 0,
		b: 0,
	}
	if (hex.length === 6) {
		color.r = parseInt(hex.slice(0, 2), 16)
		color.g = parseInt(hex.slice(2, 4), 16)
		color.b = parseInt(hex.slice(4, 6), 16)
	} else if (hex.length === 3) {
		color.r = parseInt(hex.slice(0, 1).repeat(2), 16)
		color.g = parseInt(hex.slice(1, 2).repeat(2), 16)
		color.b = parseInt(hex.slice(2, 3).repeat(2), 16)
	}

	return color
}

function hexToHSL(H) {
	// Convert hex to RGB first
	let r = 0,
		g = 0,
		b = 0
	if (H.length === 4) {
		r = '0x' + H[1] + H[1]
		g = '0x' + H[2] + H[2]
		b = '0x' + H[3] + H[3]
	} else if (H.length === 7) {
		r = '0x' + H[1] + H[2]
		g = '0x' + H[3] + H[4]
		b = '0x' + H[5] + H[6]
	}
	// Then to HSL
	r /= 255
	g /= 255
	b /= 255
	let cmin = Math.min(r, g, b),
		cmax = Math.max(r, g, b),
		delta = cmax - cmin,
		h = 0,
		s = 0,
		l = 0

	if (delta === 0) h = 0
	else if (cmax === r) h = ((g - b) / delta) % 6
	else if (cmax === g) h = (b - r) / delta + 2
	else h = (r - g) / delta + 4

	h = Math.round(h * 60)

	if (h < 0) h += 360

	l = (cmax + cmin) / 2
	s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
	s = +(s * 100).toFixed(1)
	l = +(l * 100).toFixed(1)

	return {
		h,
		s,
		l,
	}
}

function hslToHex(h, s, l) {
	l /= 100
	const a = (s * Math.min(l, 1 - l)) / 100
	const f = n => {
		const k = (n + h / 30) % 12
		const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
		return Math.round(255 * color)
			.toString(16)
			.padStart(2, '0') // convert to Hex and prefix "0" if needed
	}
	return `#${f(0)}${f(8)}${f(4)}`
}

// ratio > 0 will lighten the color
// ratio < 0 will darken the color
// ratios = {r: c1, g: c2, b: c3} where c1, c2, c3 are constants between -1 and 1
function adjustHexColor(hex, ratios) {
	if (!hex) {
		return ''
	}
	hex = hex.replace('#', '')
	const color = {
		r: 0,
		g: 0,
		b: 0,
	}
	if (hex.length === 6) {
		color.r = parseInt(hex.slice(0, 2), 16)
		color.g = parseInt(hex.slice(2, 4), 16)
		color.b = parseInt(hex.slice(4, 6), 16)
	} else if (hex.length === 3) {
		color.r = parseInt(hex.slice(0, 1).repeat(2), 16)
		color.g = parseInt(hex.slice(1, 2).repeat(2), 16)
		color.b = parseInt(hex.slice(2, 3).repeat(2), 16)
	}

	const keys = ['r', 'g', 'b']
	keys.forEach(k => {
		if (ratios[k] > 0) {
			color[k] += (255 - color[k]) * ratios[k]
		} else {
			color[k] += color[k] * ratios[k]
		}
		color[k] = Math.round(Math.min(255, Math.max(0, color[k])))
	})

	return `#${color.r.toString(16)}${color.g.toString(16)}${color.b.toString(16)}`
}

function getFinalHue(value) {
	return value % 360
}

function getFinalPercenValue(value) {
	return Math.max(0, Math.min(value, 100))
}

function autoCorrectHSL(hsl) {
	hsl.h = getFinalHue(hsl.h)
	hsl.s = getFinalPercenValue(hsl.s)
	hsl.l = getFinalPercenValue(hsl.l)
	return hsl
}

export const getCommunityColors = (theme, primaryColorHex, secondaryColorHex) => {
	var colors = {}
	if (!theme || !primaryColorHex) {
		return colors
	}

	// var colorsRGB = hexToRGB(primaryColorHex.replace('#', ''))
	var colorsHSL = hexToHSL(primaryColorHex)

	switch (theme) {
		default:
			colors = {
				secondaryColor: hslToHex(getFinalHue(colorsHSL.h), getFinalPercenValue(colorsHSL.s), 95),
			}
	}
	return colors
}

export const getThemeColors = (theme, primaryColorHex, secondaryColorHex) => {
	var colors = {}
	if (!theme || !primaryColorHex) {
		return colors
	}

	var colorsRGB = hexToRGB(primaryColorHex.replace('#', ''))
	var colorsHSL = hexToHSL(primaryColorHex)

	switch (theme) {
		case 'tyla':
			colors = {
				derived1: hslToHex(getFinalHue(colorsHSL.h - 5), getFinalPercenValue(colorsHSL.s + 10), getFinalPercenValue(colorsHSL.l + 40)),
				derived2: hslToHex(getFinalHue(colorsHSL.h + 5), getFinalPercenValue(colorsHSL.s + 15), getFinalPercenValue(colorsHSL.l + 50)),
			}
			break
		case 'minima':
			colors = {
				tone1: `rgba(${colorsRGB.r},${colorsRGB.g}, ${colorsRGB.b}, 0.05)`,
				tone2: `rgba(${colorsRGB.r},${colorsRGB.g}, ${colorsRGB.b}, 0.4)`,
			}
			break
		case 'material':
			colors = {
				tone1: `hsla(${colorsHSL.h}, ${colorsHSL.s}%, ${colorsHSL.l}%, 0.6)`,
				tone2: `hsla(${colorsHSL.h}, ${colorsHSL.s}%, ${colorsHSL.l}%, 0.4)`,
				tone3: hslToHex(getFinalHue(colorsHSL.h - 20), colorsHSL.s, 97),
				derived1: `hsla(${getFinalHue(colorsHSL.h + 30)}, ${colorsHSL.s}%, ${colorsHSL.l}%, 1)`,
				derived2: `hsla(${getFinalHue(colorsHSL.h + 15)}, ${colorsHSL.s}%, ${colorsHSL.l - 5}%, 1)`,
			}
			break
		case 'spotlight':
			colors = {
				derived1: hslToHex(colorsHSL.h, colorsHSL.s, 96),
				derived2: `hsla(${colorsHSL.h},${colorsHSL.s}%, 99%, 1)`,
			}
			break
		case 'eclipse':
			colors = {
				tone1: `hsla(${colorsHSL.h}, ${colorsHSL.s}%, ${colorsHSL.l}%, 0.6)`,
				tone2: `hsla(${colorsHSL.h}, ${colorsHSL.s}%, ${colorsHSL.l}%, 0.4)`,
				tone3: `hsla(${getFinalHue(colorsHSL.h - 20)}, ${colorsHSL.s}%, 97%, 1)`,
				derived1: `hsla(${getFinalHue(colorsHSL.h + 15)}, ${colorsHSL.s}%, ${colorsHSL.l}%, 1)`,
				derived2: hslToHex(getFinalHue(colorsHSL.h - 35), 100, 93),
				derived3: `hsla(${getFinalHue(colorsHSL.h + 75)}, 100%, 60%, 1)`,
			}
			if (secondaryColorHex) {
				var secondaryColorsHSL = hexToHSL(secondaryColorHex)
				colors.derived1 = `hsla(${getFinalHue(secondaryColorsHSL.h + 15)}, ${secondaryColorsHSL.s}%, ${secondaryColorsHSL.l}%, 1)`
			}
			break
		case 'trish':
			colors = {
				tone1: `rgba(${colorsRGB.r},${colorsRGB.g}, ${colorsRGB.b}, 0.05)`,
				tone2: `rgba(${colorsRGB.r},${colorsRGB.g}, ${colorsRGB.b}, 0.6)`,
			}
			break
	}

	// backward compatibility with people who only has 1 color
	if (!secondaryColorHex) {
		switch (theme) {
			case 'stone':
			case 'nightview':
				secondaryColorHex = '#000000'
				break
			case 'kels':
				primaryColorHex = '#F5E3E6'
				secondaryColorHex = '#EAF2FE'
				break
			case 'tyla':
				secondaryColorHex = colors.derived2
				break
			case 'eclipse':
				secondaryColorHex = primaryColorHex
				primaryColorHex = colors.derived2
				break
			case 'material':
				secondaryColorHex = colors.tone3
				break
			case 'spotlight':
				secondaryColorHex = colors.derived1
				primaryColorHex = '#2e4adc'
				break
			default:
				secondaryColorHex = '#ffffff'
		}
	}
	colors.primaryColor = primaryColorHex
	colors.secondaryColor = secondaryColorHex

	let hspThreshold = 127.5
	if (theme === 'eclipse' || theme === 'trish' || theme === 'material') {
		hspThreshold = 180
	}
	if (theme === 'tyla') {
		hspThreshold = 80
	}
	if (lightOrDark(primaryColorHex, hspThreshold) === 'light') {
		colors.buttonTextColor = '#000000'
	} else {
		colors.buttonTextColor = '#ffffff'
	}

	if (lightOrDark(secondaryColorHex, hspThreshold) === 'light') {
		colors.primaryTextColor = '#000000'
		colors.secondaryTextColor = '#00000088'
		colors.placeholderTextColor = '#00000088'
	} else {
		colors.primaryTextColor = '#ffffff'
		colors.secondaryTextColor = '#ffffff88'
		colors.placeholderTextColor = '#ffffff88'
	}
	return colors
}

function lightOrDark(color, lightThreshold = 127.5) {
	let r, g, b, hsp
	// Check the format of the color, HEX or RGB?
	if (color.match(/^rgb/)) {
		// If HEX --> store the red, green, blue values in separate variables
		color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

		r = color[1]
		g = color[2]
		b = color[3]
	} else {
		// If RGB --> Convert it to HEX: http://gist.github.com/983661
		color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

		r = color >> 16
		g = (color >> 8) & 255
		b = color & 255
	}

	// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
	hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

	// Using the HSP value, determine whether the color is light or dark
	if (hsp > lightThreshold) {
		return 'light'
	} else {
		return 'dark'
	}
}

export function calculateDefaultSecondaryColor(theme, hex) {
	const hsl = hexToHSL(hex)
	switch (theme) {
		case 'tyla':
			hsl.s += 40
			hsl.l = 98
			break
		case 'kels':
			hsl.h -= 100
			break
		case 'minima':
			hsl.l = 100
			break
		case 'nightview':
			hsl.h += 30
			hsl.s -= 20
			hsl.l -= 40
			break
		case 'spotlight':
			hsl.l = 96
			break
		case 'material':
			hsl.h -= 30
			hsl.l = 96
			break
		case 'eclipse':
			hsl.h += 25
			hsl.l = 50
			break
		case 'trish':
			hsl.h += 1
			hsl.l += 36
			break
		case 'stone':
			hsl.l = 0
			break
		default:
			hsl.l = 100 // set to white
			break
	}
	const correctHSL = autoCorrectHSL(hsl)
	return hslToHex(hsl.h, hsl.s, hsl.l)
}

// Use the following link to find the timezone our backend supports https://gist.github.com/heyalexej/8bf688fd67d7199be4a1682b3eec7568
export const unsupportedTimezoneMap = {
	'America/Ciudad_Juarez': 'US/Mountain',
	'Etc/Unkwown': 'Etc/UTC',
	'Europe/Kyiv': 'Europe/Kiev',
}

export const commonDomains = [
	'aol.com',
	'att.net',
	'comcast.net',
	'facebook.com',
	'gmail.com',
	'gmx.com',
	'googlemail.com',
	'google.com',
	'hotmail.com',
	'hotmail.co.uk',
	'mac.com',
	'me.com',
	'mail.com',
	'msn.com',
	'live.com',
	'sbcglobal.net',
	'verizon.net',
	'yahoo.com',
	'yahoo.co.uk',
	'email.com',
	'fastmail.fm',
	'games.com',
	'gmx.net',
	'hush.com',
	'hushmail.com',
	'icloud.com',
	'iname.com',
	'inbox.com',
	'lavabit.com',
	'love.com',
	'outlook.com',
	'pobox.com',
	'protonmail.ch',
	'protonmail.com',
	'tutanota.de',
	'tutanota.com',
	'tutamail.com',
	'tuta.io',
	'keemail.me',
	'rocketmail.com',
	'safe-mail.net',
	'wow.com',
	'ygm.com',
	'ymail.com',
	'zoho.com',
	'yandex.com',
	'bellsouth.net',
	'charter.net',
	'cox.net',
	'earthlink.net',
	'juno.com',
	'btinternet.com',
	'virginmedia.com',
	'blueyonder.co.uk',
	'freeserve.co.uk',
	'live.co.uk',
	'ntlworld.com',
	'o2.co.uk',
	'orange.net',
	'sky.com',
	'talktalk.co.uk',
	'tiscali.co.uk',
	'virgin.net',
	'wanadoo.co.uk',
	'bt.com',
	'sina.com',
	'sina.cn',
	'qq.com',
	'naver.com',
	'hanmail.net',
	'daum.net',
	'nate.com',
	'yahoo.co.jp',
	'yahoo.co.kr',
	'yahoo.co.id',
	'yahoo.co.in',
	'yahoo.com.sg',
	'yahoo.com.ph',
	'163.com',
	'yeah.net',
	'126.com',
	'21cn.com',
	'aliyun.com',
	'foxmail.com',
	'hotmail.fr',
	'live.fr',
	'laposte.net',
	'yahoo.fr',
	'wanadoo.fr',
	'orange.fr',
	'gmx.fr',
	'sfr.fr',
	'neuf.fr',
	'free.fr',
	'gmx.de',
	'hotmail.de',
	'live.de',
	'online.de',
	't-online.de',
	'web.de',
	'yahoo.de',
	'libero.it',
	'virgilio.it',
	'hotmail.it',
	'aol.it',
	'tiscali.it',
	'alice.it',
	'live.it',
	'yahoo.it',
	'email.it',
	'tin.it',
	'poste.it',
	'teletu.it',
	'mail.ru',
	'rambler.ru',
	'yandex.ru',
	'ya.ru',
	'list.ru',
	'hotmail.be',
	'live.be',
	'skynet.be',
	'voo.be',
	'tvcablenet.be',
	'telenet.be',
	'hotmail.com.ar',
	'live.com.ar',
	'yahoo.com.ar',
	'fibertel.com.ar',
	'speedy.com.ar',
	'arnet.com.ar',
	'yahoo.com.mx',
	'live.com.mx',
	'hotmail.es',
	'hotmail.com.mx',
	'prodigy.net.mx',
	'yahoo.ca',
	'hotmail.ca',
	'bell.net',
	'shaw.ca',
	'sympatico.ca',
	'rogers.com',
	'yahoo.com.br',
	'hotmail.com.br',
	'outlook.com.br',
	'uol.com.br',
	'bol.com.br',
	'terra.com.br',
	'ig.com.br',
	'itelefonica.com.br',
	'r7.com',
	'zipmail.com.br',
	'globo.com',
	'globomail.com',
	'oi.com.br',
]
