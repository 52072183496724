<template>
	<!-- Container splitting input and dropdown -->
	<div class="d-flex flex-column gap-8px w-100">
		<div class="w-100 d-flex justify-content-between align-items-center">
			<span class="para-3 text-bold text-dark">{{ header }}</span>
			<span class="para-3 text-light" v-if="typeof characterLimit === 'number'">{{ searchQuery.length }} / {{ characterLimit }}</span>
		</div>
		<div
			class="w-100 position-relative d-flex flex-col gap-12px"
			@keydown.down.prevent="handleKeyDown('down')"
			@keydown.up.prevent="handleKeyDown('up')"
			@keydown.enter.prevent="handleEnterKey"
		>
			<!-- Input -->
			<div class="w-100 d-flex flex-row gap-8px flex-wrap align-items-center input-container" :class="{ disabled: disabled }">
				<!-- Selected Items -->
				<div v-for="(item, i) in selectedItems" :key="i" class="para-3 text-bold item d-flex gap-4px align-items-center">
					{{ item[label] }}
					<InlineSvg class="remove-tag-icon" src="/images/icons/ic-close.svg" @click="handleRemoveSelectedItem(i)" v-if="!disabled" />
				</div>

				<input
					ref="inputElement"
					type="text"
					:placeholder="placeholder"
					class="para-3 flex-grow-1 input-text"
					style="outline: none"
					@focus="handleInputFocus"
					@blur="handleInputBlur"
					@keydown.backspace="handleBackspace"
					@input="handleInputChange"
					v-model="searchQuery"
					:disabled="disabled"
				/>
			</div>

			<!-- Dropdown -->
			<Transition name="fade">
				<div
					v-show="isInputFocused"
					ref="dropdownContainer"
					class="dropdown"
					:style="{
						height: dropdownHeight,
						'overflow-y': filteredItems.length > 3 ? 'scroll' : 'auto',
					}"
				>
					<div v-if="filteredItems.length === 0" class="d-flex flex-column ">
						<div
							v-if="canAddNewItem && !filteredItems.find(item => item.label === searchQuery) && searchQuery.length > 0"
							class="para-3 d-flex gap-4px justify-content-center add-new-container"
							@click="handleAddNewTag"
							:disabled="disabled"
						>
							{{ addNewItemPrefixCopy }} <span class="text-bold">"{{ searchQuery }}"</span>
						</div>
					</div>

					<div v-else class="d-flex flex-column">
						<!-- List item -->
						<div
							v-for="(item, i) in filteredItems"
							:key="i"
							:class="{ 'is-pointed': i === selectedIndex }"
							class="para-3 text-bold list-item-container d-flex justify-content-between align-items-center"
							@click="handleSelectItem(item)"
							@mouseenter="handleMouseEnter(i)"
							@mouseleave="handleMouseLeave"
							ref="listItemRefs"
							:disabled="disabled"
						>
							<!-- Tag -->
							<div class="item">
								{{ item[label] }}
							</div>

							<div v-if="selectedIndex === i" class="para-4 d-flex gap-4px align-items-center enter-to-select">
								<InlineSvg class="enter-icon" src="/images/icons/ic-enter-press.svg" /> Enter to select
							</div>
						</div>

						<div
							v-if="canAddNewItem && !filteredItems.find(item => item[label] === searchQuery) && searchQuery.length > 0"
							class="para-3 d-flex justify-content-center text-primary-color gap-4px add-new-container"
							@click="handleAddNewTag"
							:disabled="disabled"
						>
							{{ addNewItemPrefixCopy }} <span class="text-bold">"{{ searchQuery }}"</span>
						</div>
					</div>
				</div>
			</Transition>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AppNewMultiSelect',
		props: {
			placeholder: {
				type: String,
				default: '',
			},
			label: {
				type: String,
				default: '',
			},
			trackBy: {
				type: String,
				default: '',
			},
			items: {
				type: Array,
				require: true,
				default: () => [],
			},
			selectedItems: {
				type: Array,
				require: true,
				default: () => [],
			},
			singleItemSelect: {
				type: Boolean,
				default: false,
			},
			canAddNewItem: {
				type: Boolean,
				default: false,
			},
			header: {
				type: String,
				default: '',
			},
			characterLimit: {
				type: Number,
				default: null,
			},
			addNewItemPrefixCopy: {
				type: String,
				default: '',
			},
			disabled: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				isInputFocused: false,
				searchQuery: '',
				selectedIndex: 0,
			}
		},
		mounted() {
			// Add event listener for keydown event to listen for Enter key press
			document.addEventListener('keydown', this.handleDocumentKeyDown)
		},
		beforeUnmount() {
			document.removeEventListener('keydown', this.handleDocumentKeyDown)
		},
		methods: {
			handleInputFocus() {
				this.isInputFocused = true
			},
			handleInputBlur() {
				this.isInputFocused = false
			},
			// Handle direction movement
			handleKeyDown(direction) {
				if (!this.isInputFocused) return

				if (direction === 'down') {
					if (this.selectedIndex < this.filteredItems.length - 1) {
						this.selectedIndex++
					}
				} else if (direction === 'up') {
					if (this.selectedIndex > 0) {
						this.selectedIndex--
					}
				}

				this.scrollIntoView()
			},
			// If we scroll out of view, push the scroll bar up / down
			scrollIntoView() {
				if (this.$refs.listItemRefs && this.$refs.listItemRefs[this.selectedIndex]) {
					const listItem = this.$refs.listItemRefs[this.selectedIndex]
					const dropdownContainer = this.$refs.dropdownContainer
					const listItemRect = listItem.getBoundingClientRect()
					const containerRect = dropdownContainer.getBoundingClientRect()

					if (listItemRect.bottom > containerRect.bottom) {
						dropdownContainer.scrollTop += listItemRect.bottom - containerRect.bottom
					} else if (listItemRect.top < containerRect.top) {
						dropdownContainer.scrollTop -= containerRect.top - listItemRect.top
					}
				}
			},
			handleEnterKey() {
				if (this.selectedIndex !== -1) {
					this.handleSelectItem(this.filteredItems[this.selectedIndex])
				} else if (this.searchQuery.length > 0 && this.canAddNewItem) {
					this.handleAddNewTag()
				}

				if (this.singleItemSelect) {
					this.handleInputBlur()
					this.$refs.inputElement.blur()
				}

				this.selectedIndex = 0
			},
			// Method to handle backspace key press
			handleBackspace(event) {
				if (this.searchQuery === '' && this.selectedItems.length > 0) {
					event.preventDefault()
					this.selectedItems.pop()
				}
			},
			// Method to handle item click
			handleSelectItem(item) {
				// Blur the input only if singleItemSelect is false
				if (!this.singleItemSelect) {
					this.$refs.inputElement.focus()
					this.handleInputFocus()
				}

				if (this.singleItemSelect) {
					this.selectedItems.length = 0
					this.selectedIndex = 0
				}

				this.selectedItems.push(item)
				this.$emit('customSelectFunction', item)
				this.searchQuery = ''
			},
			// Method to remove selected item
			handleRemoveSelectedItem(index) {
				const removedItem = this.selectedItems[index]
				this.selectedItems.splice(index, 1)
				this.$emit('customRemoveFunction', removedItem)
			},
			// Method to handle mouse enter event on list item
			handleMouseEnter(index) {
				this.selectedIndex = index
			},
			// Method to handle mouse leave event on list item
			handleMouseLeave() {
				// Reset selectedIndex when mouse leaves list item
				this.selectedIndex = -1
			},
			// Method to handle adding a new tag
			handleAddNewTag() {
				// Determine the structure of the items array
				const newItem = {}

				if (this.items.length > 0) {
					const firstItem = this.items[0]
					if (typeof firstItem === 'object') {
						// Get the keys of the first item
						const keys = Object.keys(firstItem)
						// Iterate over the keys and set them in the new item
						keys.forEach(key => {
							newItem[key] = this.searchQuery
						})
					}
				} else {
					// If we don't have any tags, we'll structure the object with the label passed in
					newItem[this.label] = this.searchQuery

					if (this.label !== this.trackBy) {
						newItem[this.trackBy] = this.searchQuery
					}
				}

				this.handleSelectItem(newItem)
			},
			handleInputChange() {
				if (this.selectedIndex !== -1) this.selectedIndex = 0

				if (this.characterLimit !== null && this.searchQuery.length > this.characterLimit) {
					this.searchQuery = this.searchQuery.slice(0, this.characterLimit)
				}
			},
		},
		computed: {
			// Calculate the height of the dropdown based on the number of items
			dropdownHeight() {
				if (this.filteredItems.length > 3) {
					return '150px'
				}

				return 'auto'
			},
			// Filter items based on the search query and exclude selected items
			filteredItems() {
				const filteredItemsArray = this.items.filter(item => {
					const isSelected = this.selectedItems.some(selectedItem => selectedItem[this.trackBy] === item[this.trackBy])
					return item[this.label].toLowerCase().includes(this.searchQuery.toLowerCase()) && !isSelected
				})

				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.selectedIndex = filteredItemsArray.length === 0 ? -1 : 0

				return filteredItemsArray
			},
		},
	}
</script>

<style lang="scss" scoped>
	.input-container {
		border: 1px solid var(--stan-gray-dark-color);
		padding: 12px;
		min-height: 52px;
		border-radius: 8px;

		.remove-tag-icon {
			height: 14px;
			width: 14px;
			cursor: pointer;
		}

		&.disabled {
			cursor: not-allowed;
			border: 1px solid var(--stan-gray-soft-color);

			.input-text {
				cursor: not-allowed;
				&::placeholder {
					color: var(--stan-gray-primary-color) !important;
				}
			}
		}
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.15s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}

	.dropdown {
		z-index: 1;
		position: absolute;
		margin-top: 4px;
		top: 100%;
		left: 0;
		width: 100%;
		border-radius: 8px;
		background-color: white;
		box-shadow: var(--stan-box-shadow);
	}

	.list-item-container {
		padding: 12px;
		width: 100%;
		cursor: pointer;

		&.is-pointed {
			background-color: var(--stan-primary-primary-color);

			.item {
				color: white;
				background-color: #7d74ff;
			}

			.enter-to-select {
				color: var(--stan-primary-soft-color);
				svg::v-deep {
					path {
						stroke: var(--stan-primary-soft-color);
					}
				}

				.enter-icon {
					height: 12px;
					width: 12px;
				}

				@media (max-width: 992px) {
					visibility: hidden;
				}
			}
		}
	}

	.item {
		background-color: #ebebff;
		border-radius: 5px;
		padding: 5px 8px;
		width: fit-content;
		color: #6355ff;
	}

	.add-new-container {
		width: 100%;
		background-color: white;
		border-radius: 0px 0px 8px 8px;
		padding: 15px 0px;
		color: var(--stan-primary-primary-color);
		cursor: pointer;

		&:hover {
			background-color: var(--stan-primary-primary-color);
			color: white;
		}
	}
</style>
