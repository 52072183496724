var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        id: "import-audience-modal",
        title: _vm.modalTitle,
        staticDataBackdrop: true,
        subheading: _vm.modalSubheading
      },
      on: { close: _vm.closeModal }
    },
    [
      _c("input", {
        key: _vm.inputKey,
        ref: "fileInput",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: "text/csv" },
        on: { change: _vm.filePreview }
      }),
      _vm._v(" "),
      _vm.fileImportErrorCode || (_vm.file && _vm.rows.length === 0)
        ? _c("div", { staticClass: "pb-3" })
        : _c("div", [
            !_vm.file
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "single-import mb-5 mt-4 pt-3" },
                    [
                      _c("AppInput", {
                        staticClass: "mb-3",
                        attrs: {
                          placeholder: "Name",
                          error: _vm.error.full_name
                        },
                        on: {
                          change: function($event) {
                            _vm.error.full_name = []
                          }
                        },
                        model: {
                          value: _vm.fan.full_name,
                          callback: function($$v) {
                            _vm.$set(_vm.fan, "full_name", $$v)
                          },
                          expression: "fan.full_name"
                        }
                      }),
                      _vm._v(" "),
                      _c("AppInput", {
                        staticClass: "mb-3",
                        attrs: { placeholder: "Email", error: _vm.error.email },
                        on: {
                          change: function($event) {
                            _vm.error.email = []
                          }
                        },
                        model: {
                          value: _vm.fan.email,
                          callback: function($$v) {
                            _vm.$set(_vm.fan, "email", $$v)
                          },
                          expression: "fan.email"
                        }
                      }),
                      _vm._v(" "),
                      _c("AppPhoneInput", {
                        key: _vm.tick,
                        ref: "phoneNumber",
                        staticClass:
                          "form-group has-country align-items-center",
                        attrs: { error: _vm.error.phone_number },
                        on: { updatePhone: _vm.updatePhoneNumber }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "w-100 mt-3" },
                        [
                          _vm.rows.length === 0 || _vm.fileImportErrorCode
                            ? _c(
                                "AppButton",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    name: "add-contact-audiece-modal",
                                    fullWidth: true,
                                    loading: _vm.csvImportProcessing,
                                    disabled: _vm.csvImportProcessing
                                  },
                                  on: { click: _vm.saveSingleContact }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\tAdd Contact\n\t\t\t\t\t"
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "group-import text-center" }, [
                    _c("h3", { staticClass: "text-bold mb-3" }, [
                      _vm._v("...or import a list")
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "para-2 text-light" }, [
                      _vm._v(
                        "\n\t\t\t\t\tPlease upload a CSV file with the following columns: Email Address, First Name, Last Name, and Phone Number.\n\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "para-2 text-light mb-0 pb-3" }, [
                      _vm._v("Maximum of 5,000 contacts per account.")
                    ])
                  ])
                ])
              : _c("div", { staticClass: "my-4" }, [
                  _vm.rows.length > 0
                    ? _c(
                        "div",
                        [
                          _c("EditFanTags", {
                            ref: "editFanTags",
                            staticClass: "tag-selection",
                            attrs: {
                              showTitle: false,
                              searchable: false,
                              mode: "single-tag"
                            },
                            on: { "tags-updated": _vm.fanTagsUpdated }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
          ]),
      _vm._v(" "),
      _vm.rows.length !== 0 && !_vm.fileImportErrorCode
        ? _c(
            "div",
            { staticClass: "w-100 mt-3 highlight-card" },
            [
              _c(
                "AppCheckbox",
                {
                  model: {
                    value: _vm.termsAgree,
                    callback: function($$v) {
                      _vm.termsAgree = $$v
                    },
                    expression: "termsAgree"
                  }
                },
                [
                  _vm._v(
                    "\n\t\t\tI acknowledge that I have read and understand the\n\t\t\t"
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://assets.stanwith.me/legal/terms-of-service.pdf",
                        target: "_new"
                      }
                    },
                    [_vm._v("Terms of Service")]
                  ),
                  _vm._v(" and the\n\t\t\t"),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://assets.stanwith.me/legal/CASL.pdf"
                      }
                    },
                    [_vm._v("Anti-Spam Rules")]
                  ),
                  _vm._v(".\n\t\t")
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.fileImportErrorHint
        ? _c("div", {
            staticClass: "text-center para-2 text-light mb-5",
            domProps: { innerHTML: _vm._s(_vm.fileImportErrorHint) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-100 mt-3" },
        [
          _vm.rows.length === 0 || _vm.fileImportErrorCode
            ? _c(
                "AppButton",
                {
                  staticClass: "w-100",
                  attrs: {
                    name: "upload-csv-audience-modal",
                    outline: "",
                    fullWidth: true,
                    loading: _vm.csvImportProcessing,
                    disabled: _vm.csvImportProcessing
                  },
                  on: { click: _vm.openFileUploadDialog }
                },
                [_vm._v("\n\t\t\tUpload\n\t\t")]
              )
            : _c(
                "AppButton",
                {
                  staticClass: "w-100",
                  attrs: {
                    name: "confirm-import-audience-modal",
                    fullWidth: true,
                    loading: _vm.csvImportProcessing,
                    disabled: _vm.csvImportProcessing || !_vm.termsAgree
                  },
                  on: { click: _vm.handleFileUpload }
                },
                [_vm._v("\n\t\t\tConfirm\n\t\t")]
              )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }