<template>
	<AppModal id="import-audience-success-modal" :staticDataBackdrop="true" @close="closeModal" action="Okay!" @cta="goToRecipients">
		<div class="pb-1 d-flex w-100 justify-content-center"><InlineSvg src="/images/emojis/party-popper.svg" /></div>
		<div class="m-4 text-bold text-center">Woohoo!</div>
		<div class="m-4 text-light para-2 text-center">
			<p>
				Your import is running in the background and will finish shortly. If you want to send marketing emails to your imported contacts,
				they'll need to <span class="text-bold">opt in!</span>
			</p>
			<p>
				You can send an opt-in email to your contacts in
				<span class="d-block text-bold">Email Flows > Recipients.</span>
			</p>
		</div>
	</AppModal>
</template>
<script>
	export default {
		props: {
			numberImported: {
				type: Number,
				default: 0,
			},
		},
		methods: {
			goToRecipients() {
				this.closeModal()
			},
			closeModal() {
				$('#import-audience-success-modal').modal('hide')
			},
		},
	}
</script>

<style lang="scss" scoped></style>
