import { Node, mergeAttributes } from '@tiptap/vue-2'

export default Node.create({
	name: 'VideoEmbed',
	group: 'inline',
	inline: true,
	atom: true,
	draggable: true,

	addAttributes() {
		return {
			src: {
				default: 'https://stan.store',
			},
			style: {
				default: 'width:100%; height:100%; border:0px; aspect-ratio:1.65; overflow:hidden;',
			},
			scrolling: {
				default: 'no',
			},
		}
	},
	parseHTML() {
		return [
			{
				tag: 'iframe',
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return ['iframe', mergeAttributes(HTMLAttributes), `{${HTMLAttributes.src}}`, `{${HTMLAttributes.style}}`, `{${HTMLAttributes.scrolling}}`]
	},

	addNodeView() {
		return ({ node }) => {
			const dom = document.createElement('div')
			dom.classList.add('embed-video')

			const content = document.createElement('iframe')
			content.src = node.attrs.src
			content.scrolling = node.attrs.scrolling
			content.style = node.attrs.style

			content.innerHTML = node.attrs.name
			dom.append(content)

			return {
				dom,
			}
		}
	},
})
